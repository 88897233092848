import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function ClaimEditForm({ }) {
  let { id } = useParams();



  const items = [


    {
      key: 'mt_user_id',
      label: 'Mt User Id ',
      type: 'text',
      required: true,
    },

    {
      key: 'created_at',
      label: 'Date',
      type: 'date',
      required: true
    },

    {
      key: 'comments',
      label: 'Comments',
      type: 'text',
      required: true
    },



  ];
  const initValue = {
    mt_user_id: '',
    created_by: '',
    comments: '',
    created_at: '',

  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/claim/${id}`}
      getPath={`${BASE_API_URL}/claim/${id}`}
      successText="User created succesfully"
      errorText="An error has occurred, please try again later"
      successUrl="/claims"
      title="Claim Edit"
      id={id}
    />
  );
}

export default ClaimEditForm;

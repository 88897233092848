
const customFetch = async (url, method = 'GET', body = null) => {
    try {
        const token = localStorage.getItem('token');
        
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : ''
        };

        const options = {
            method,
            headers
        };

        if (body && (method === 'POST' || method === 'PUT')) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${url}`, options);

        if (response.status === 403 || response.status === 401) {
            // Suponiendo que tenemos una función 'logout' que borra al usuario de la sesión
            logout(); 
            return  {error:'Unauthorized access. Logging out...' };
        }

        const responseData = await response.json();
        
        if (!response.ok) {
            return  {error:responseData.msg || 'An error occurred, try again later' };
        }

        return responseData;

    } catch (error) {
        // Pasar error al siguiente manejador de errores
        return {error};
    }
};

const logout = () => {
    localStorage.removeItem('token');
    // Aquí puede agregar más lógica, por ejemplo, redireccionar al usuario a la página de inicio de sesión
};

// Exponiendo los servicios
const apiService = {
    get: (url) => customFetch(url, 'GET'),
    post: (url, body) => customFetch(url, 'POST', body),
    put: (url, body) => customFetch(url, 'PUT', body),
    delete: (url) => customFetch(url, 'DELETE')
};

export default apiService;

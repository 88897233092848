// @mui material components
import { utils, writeFile } from 'xlsx';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Input, ListItemText, MenuItem, MenuList, Paper, TextField } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { GridActionsCellItem } from '@mui/x-data-grid';

import GetAppIcon from '@mui/icons-material/GetApp';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import AlertDialog from 'components/Modal';
import apiService from 'core/customFetch';
import { useMessageContext } from 'context/message';

export const FilterInput = (props) => {
  const { item, applyValue, focusElementRef } = props;
  const filterRef = useRef(null);
  useImperativeHandle(focusElementRef, () => {
    filterRef.current.focus();
  });
  return (
    <div>
      <TextField
        inputRef={filterRef}
        onChange={(e) => {
          applyValue({ ...item, value: e.target.value });
        }}
        label="value"
        value={item.value}
        InputLabelProps={{
          shrink: true
        }}
        variant="standard"
        className="filterFormValueInput"
      />
    </div>
  );
};

export const FilterDateInput = (props) => {
  const { item, applyValue, focusElementRef } = props;
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const filterRef = useRef(null);
  useEffect(() => {
    if (from && to && from > to) {
      setTo(null);
    }
    applyValue({
      ...item,
      value: from?.format('YYYY-MM-DD') + ',' + to?.format('YYYY-MM-DD')
    });
  }, [from, to]);

  useEffect(() => {
    if (filterRef?.current) {
      // add class to filterRef.current
      let isParent = false;
      let parentCounters = 0;
      let current = filterRef.current;
      while (!isParent || parentCounters < 20) {
        current = current.parentElement;
        if (current.classList.contains('MuiDataGrid-panelWrapper')) {
          isParent = true;
          break;
        }
        parentCounters++;
      }
      current.classList.add('filterFormValueInput');
    }
  }, [filterRef]);

  // useImperativeHandle(focusElementRef, () => {
  //   filterRef.current.focus();
  // });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '420px'
      }}
      ref={filterRef}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker value={from} onChange={(newValue) => setFrom(newValue)} />
          <DatePicker value={to} onChange={(newValue) => setTo(newValue)} />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export const stringFilterOperators = [
  {
    label: 'Contains',
    value: 'contains',
    InputComponent: (props) => <FilterInput {...props} />
  },
  {
    label: 'Equals',
    value: 'equals',
    InputComponent: (props) => <FilterInput {...props} />
  }
];

export const numberFilterOperators = [
  {
    label: '>',
    value: 'gt',
    InputComponent: (props) => <FilterInput {...props} />
  },
  {
    label: '>=',
    value: 'gte',
    InputComponent: (props) => <FilterInput {...props} />
  },
  {
    label: '<',
    value: 'lt',
    InputComponent: (props) => <FilterInput {...props} />
  },
  {
    label: '<=',
    value: 'lte',
    InputComponent: (props) => <FilterInput {...props} />
  },
  {
    label: 'equals',
    value: 'equals',
    InputComponent: (props) => <FilterInput {...props} />
  }
];

export const dateFilterOperators = [
  {
    label: 'between',
    value: 'date_between',
    InputComponent: (props) => <FilterDateInput {...props} />
  }
];

const getActionsBtns = (showUrl, customShowUrl, editUrl, deleteUrl, callback) => {
  if (!showUrl && !editUrl && !deleteUrl) return [];
  return [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width:
        (3 - ((showUrl || customShowUrl) ? 0 : 1) - (editUrl ? 0 : 1) - (deleteUrl ? 0 : 1)) * 60,
      cellClassName: 'actions',
      getActions: (item) => {
        const row = item.row;
        const result = [];
        if ((showUrl || customShowUrl))
          result.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="Show"
              className="textPrimary"
              onClick={callback('show', row)}
              color="inherit"
            />
          );

        if (editUrl)
          result.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={callback('edit', row)}
              color="inherit"
            />
          );
        if (deleteUrl)
          result.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={callback('delete', row)}
              color="inherit"
            />
          );
        return result;
      }
    }
  ];
};

function CustomTable({
  additionalFilterComponent,
  columns,
  url,
  title = 'Table',
  addUrl,
  showUrl,
  customShowUrl,
  editUrl,
  deleteUrl,
  disableRemoteDownlaod = false
}) {
  const { messageData, setMessageData } = useMessageContext();
  const [loading, setLoading] = useState(false);
  const [loadedData, setLoadedData] = useState([]);

  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [total_elements, setTotalElements] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [remotePerPage, setRemotePerPage] = useState(200);
  const [remotePage, setRemotePage] = useState(1);
  const [per_page, setPerPage] = useState(20);

  

  const [infinityScroolPage, setInfinityScroolPage] = useState(1);

  const [sortColumn, setSortColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');

  const [showDownloadMenu, setShowDownloadMenu] = useState(false);
  const [sortParams, setSortParams] = useState('-id');

  const [open, setOpen] = useState(null);
  const [handleClose, setHandleClose] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [actions, setActions] = useState([]);
  const [reload, setReload] = useState(1);
  const [response, setResponse] = useState(null);
  const handleLocalDownload = (data) => {
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(data); // Reemplaza 'c' con tus datos
    utils.book_append_sheet(workbook, worksheet, 'Sheet2');
    writeFile(workbook, (title || 'download').replace(/ /g, '_').toLowerCase() + '.xlsx');
  };
  const handleRemoteDownloadMenu = () => {
    setShowDownloadMenu(!showDownloadMenu);
    
  };
const handleRemoteDownload = () => {
  apiService.get(
      `${url}?page=${remotePage}&per_page=${remotePerPage}&sort_by=${sortParams}${filterValue ? `&${filterValue}` : ''
      }`
    )
      .then((data) => {
        if (data?.error) {

          return
        }
        handleLocalDownload(data.content)
      });
}

  const handleAddClick = () => {
    navigate(addUrl);
  };

  const handleActionClick = (type, row) => () => {
    if (type === 'show') {
      // const computedShowUrl = customShowUrl ? customShowUrl(tra) showUrl; 
      if (customShowUrl) {
        console.log(customShowUrl(row))
        navigate(customShowUrl(row));
      } else {

        navigate(`${showUrl}/${row._id}`);
      }
    } else if (type === 'edit') {
      navigate(`${editUrl}/${row._id}`);
    } else {
      setModalTitle('Are you sure?');
      setDescription('You are about to delete this record');
      setActions([
        {
          label: 'Cancel',
          value: false
        },
        {
          label: 'Delete',
          value: true
        }
      ]);
      setOpen(true);
      setHandleClose(() => (value) => {
        if (value) {
          apiService.delete(`${deleteUrl}/${row._id}`).then((res) => {
            setOpen(false);

            if (res.error) {

              return setMessageData({
                snackOpen: true,
                snackSeverity: 'error',
                snackMsg: res.error,
                setSnackOpen: setMessageData
              })
            }
            setFilterValue('');
            setPage(1);
            setReload(reload + 1);
          }).catch(err => {
            setMessageData({
              snackOpen: true,
              snackSeverity: 'error',
              snackMsg: err.error,
              setSnackOpen: setMessageData
            })
          })
        } else {
          setOpen(false);
        }
      });
    }
  };

  const handleScroll = () => {

    const container = document.querySelector('body'); // Ajusta el selector al contenedor de tu tabla
    console.log('handleScroll', container, infinityScroolPage)
    if (container) {
      if (
        container.scrollTop + container.clientHeight >= container.scrollHeight - 100 &&
        !loading &&
        infinityScroolPage < total_pages
      ) {
        // Cuando el usuario llega al final de la página y hay más páginas disponibles
        setLoading(true);
        const nextPage = infinityScroolPage + 1;
        console.log('nextPage', nextPage)
        apiService
          .get(
            `${url}?page=${nextPage}&per_page=${per_page}&sort_by=${sortParams}${filterValue ? `&${filterValue}` : ''
            }`
          )
          .then((data) => {
            setLoading(false);
            if (data?.error) {
              setMessageData({
                snackOpen: true,
                snackSeverity: 'error',
                snackMsg: data.error,
                setSnackOpen: setMessageData,
              });
              return;
            }
            // Agrega los nuevos datos cargados a la lista existente
            setLoadedData((prevData) => [...prevData, ...data.content])
            setInfinityScroolPage(data.page_info?.page);

     
          });
      }
    }
  };

useEffect(() => {
  if(infinityScroolPage > 1){
    document.querySelector('.MuiDataGrid-virtualScroller').scrollTop = (document.querySelector('.MuiDataGrid-virtualScroller').scrollHeight / infinityScroolPage) * (infinityScroolPage - 1) - 50
  }
}, [loadedData, infinityScroolPage]);

  const handleFilterChanges = (params) => {
    if (params?.items?.length === 0) return;
    setFilterValue(
      params.items.reduce((acc, curr) => {
        if (!curr.value) return acc;
        return `${acc}&query_${curr.field}=${curr.value}&query_${curr.field}_operator=${curr.operator}`;
      }, '')
    );
  };

  useEffect(() => {
    setSortParams(`${sortDirection === 'asc' ? '' : '-'}${sortColumn}`);
  }, [sortColumn, sortDirection]);


  const debounceTimeoutRef = useRef(null);

  const handleDebouncedScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;

    // Comprobar si el usuario ha llegado al final del contenedor
    if (scrollTop + clientHeight >= scrollHeight - 10) {  // El "- 10" es un umbral para manejar el scroll cerca del final, puedes ajustarlo según tus necesidades
      // Cancelar el debounce anterior si existe
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      // Configurar un nuevo debounce
      debounceTimeoutRef.current = setTimeout(() => {
        // Tu lógica de carga de datos aquí
        handleScroll();
      }, 300); // Ajusta el tiempo de debounce según tus necesidades
    }
  };

  useEffect(() => {
    const container = document.querySelector('.custom-table .MuiDataGrid-virtualScroller'); // Ajusta el selector al contenedor de tu tabla
    if (container) {
      container.addEventListener('scroll', handleDebouncedScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleDebouncedScroll);
      }
    };
  }, [page, loading, total_pages]);

  useEffect(() => {
    apiService.get(
      `${url}?page=${page}&per_page=${per_page}&sort_by=${sortParams}${filterValue ? `&${filterValue}` : ''
      }`
    )
      .then((data) => {
        if (data?.error) {
          setMessageData({
            snackOpen: true,
            snackSeverity: 'error',
            snackMsg: data.error,
            setSnackOpen: setMessageData
          })
          return
        }
        setResponse(data);
        setRows(
          data.content.map((a) => ({
            ...a,
            _id: a._id,
            id: a.id || a._id || a.ID
          }))
        );
        setTotalElements(data.page_info?.total_elements);
        setTotalPages(data.page_info?.total_pages);
        setPage(data.page_info?.page);
        setPerPage(data.page_info?.per_page);
      });
  }, [
    url,
    total_elements,
    total_pages,
    page,
    per_page,
    sortParams,
    filterValue,
    reload
  ]);

  useEffect(() => {
    setLoadedData([]);
    setPage(1);
  }, [
    filterValue,
  ])

  const allData = [...rows, ...loadedData].filter((item, index, arr) => arr.findIndex(t => (t._id === item._id)) === index).map((a) => ({
    ...a,
    _id: a._id,
    id: a.id || a._id || a.ID
  }));

  const cols = [
    {
      headerName: '#', field: '#', width: 200, align: 'left',
      renderCell: (row, i) => {
        return allData?.findIndex((a) => a._id === row.row._id) + 1
      },
    },
    ...getActionsBtns(showUrl, customShowUrl, editUrl, deleteUrl, handleActionClick),
    ...columns
  ];
  console.log('allData', allData)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title={modalTitle}
        description={description}
        actions={actions}
      />
      {additionalFilterComponent ? additionalFilterComponent : null}
      <MDBox pt={6} pb={3} className="first-child">

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
              </MDBox>
              <div>
                <Button
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => handleLocalDownload(response.content)}
                >
                  Descargar Local
                </Button>
                {disableRemoteDownlaod ? <></> : <Button
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={handleRemoteDownloadMenu}
                >
                  Descargar Remoto
                </Button>}
                {showDownloadMenu ? (<Paper sx={{ width: 200 }}>
                  <MenuList dense>
                    <MenuItem>
                      <TextField label="page number" placeholder='enter page' defaultValue={remotePage} onChange={(e)=>{
                        setRemotePage(e.target.value)
                      }} />
                    </MenuItem>
                    <MenuItem>
                      <TextField label="items per page" placeholder='enter items per page' defaultValue={remotePerPage} onChange={(e)=>{
                        setRemotePerPage(e.target.value)
                      }} />
                    </MenuItem>
                    <MenuItem>
                      <Button onClick={handleRemoteDownload} >
                        Download
                      </Button>
                    </MenuItem>

                  </MenuList>
                </Paper>) : <></>}
              </div>

              <MDBox pt={3}>
                <DataGrid
                  className='custom-table'
                  sx={{
                    maxHeight: "700px"
                  }}
                  // rows={rows}
                  rows={allData?.length > rows?.length ? allData : rows}

                  columns={cols}
                  paginationModel={{
                    pageSize: allData?.length > rows?.length ?
                      allData?.length
                      : per_page || 20,
                    page: page - 1 || 0
                  }}
                  rowCount={total_elements}
                  slots={{
                    toolbar: () => {
                      return (
                        <GridToolbarContainer>
                          {addUrl ? (
                            <Button
                              color="primary"
                              startIcon={<AddIcon />}
                              onClick={handleAddClick}
                            >
                              Add record
                            </Button>
                          ) : null}
                        </GridToolbarContainer>
                      );
                    }
                  }}
                  sortingMode="server"
                  paginationMode="server"
                  filterMode="server"
                  pageSizeOptions={[20, 50, 100]}
                  onSortModelChange={(params) => {
                    if (params.length === 0) return;
                    setSortColumn(params[0].field);
                    setSortDirection(params[0].sort);
                  }}
                  onPaginationModelChange={(params) => {
                    setPerPage(params.pageSize);
                    setPage(params.page + 1);
                  }}
                  onFilterModelChange={handleFilterChanges}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomTable;

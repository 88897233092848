import React, { useState } from "react";
import { BASE_API_URL } from "constants";
import CustomForm from "components/CustomForm";

function AcademyRemove() {
  // Base items array (common for all types)
  const items = [
    {
      key: "title",
      label: "Title",
      type: "select",
      required: true,
      url: `${BASE_API_URL}/academic_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.key;
      },
    },
  ];

  const initValue = {
    title: "initial_value",
  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/academy/remove`}
      successText="Item removed successfully"
      errorText="An error has occurred, please try again later"
      successUrl="/academy/remove"
      title="Academy Remove"
    />
  );
}

export default AcademyRemove;

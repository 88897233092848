import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';

function TaskLogsTable() {
    const columns = [
        {
            headerName: 'App',
            field: 'app',
            width: 100,
            align: 'left'
        },

        {
            headerName: 'Environment',
            field: 'environment',
            width: 100,
            align: 'left',

        },

        {
            headerName: 'Task Name',
            field: 'task_name',
            width: 200,
            align: 'left'
        },
        {
            headerName: 'Start Date',
            field: 'start_date',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators

        },
        {
            headerName: 'End Date',
            field: 'end_date',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators

        },
        {
            headerName: 'Elapsed Time',
            field: 'elapsed_time',
            width: 200,
            align: 'left'
        },

        {
            headerName: 'Error',
            field: 'error',
            width: 200,
            align: 'left',

        },

        {
            headerName: 'Stack Trace',
            field: 'stack_trace',
            width: 100,
            align: 'left',
        },

        {
            headerName: 'Created at',
            field: 'created_at',
type: 'datetime',
            width: 100,
            align: 'left',
            filterOperators: numberFilterOperators
        },

    ];

    return (
        <CustomTable
            title="Task Logs List"
            addUrl="/task_log/create"
            editUrl="/task_log/edit"
            deleteUrl={`${BASE_API_URL}/task_log`}
            columns={columns}
            url={`${BASE_API_URL}/task_logs`}
        />
    );
}

export default TaskLogsTable
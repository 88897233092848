import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';

function RemarkTable() {


  const columns = [
    { headerName: 'Key', field: 'key', width: 200, align: 'left' },
    { headerName: 'Target Profit', field: 'target_profit', width: 200, align: 'left' },
    { headerName: 'Amount', field: 'amount', width: 200, align: 'left' },
    { headerName: 'Offer id development', field: 'offer_id_development', width: 200, align: 'left'},
    { headerName: 'Offer id production', field: 'offer_id_production', width: 200, align: 'left'},
    { headerName: 'Daily drawdown', field: 'daily_drawdown', width: 200, align: 'left'},
    { headerName: 'Max drawdown', field: 'max_drawdown', width: 200, align: 'left'},
    { headerName: 'Broker', field: 'broker', width: 200, align: 'left'},
    { headerName: 'Sku', field: 'sku', width: 200, align: 'left'},
    { headerName: '30 seconds rule', field: '30_seconds_rule', width: 200, align: 'left'},
    { headerName: 'Is Tournament', field: 'is_tournament', width: 200, align: 'left'},
    { headerName: 'Is Accelerated', field: 'is_accelerated', width: 200, align: 'left'},
    { headerName: 'Is Funded', field: 'is_funded', width: 200, align: 'left'},
    { headerName: 'Min days traded', field: 'min_days_traded', width: 200, align: 'left'},
    { headerName: 'Daily loss limit', field: 'daily_loss_limit', width: 200, align: 'left'},
    { headerName: 'Phase', field: 'phase', width: 200, align: 'left'},
    { headerName: 'Phase target', field: 'phase_target', width: 200, align: 'left'},
    { headerName: 'Trailing loss limit', field: 'trailing_loss_limit', width: 200, align: 'left'},
    { headerName: 'Next Remarks Id', field: 'next_remark_id', width: 200, align: 'left'},
    { headerName: 'Elo lost trade', field: 'elo_for_lost_trade', width: 200, align: 'left'},
    { headerName: 'Elo phase lost', field: 'elo_for_phase_lost', width: 200, align: 'left'},
    { headerName: 'Elo phase win', field: 'elo_for_phase_win', width: 200, align: 'left'},
    { headerName: 'Elo phase win trade', field: 'elo_for_win_trade', width: 200, align: 'left'},
    { headerName: 'Tournament Name', field: 'tournament_name', width: 200, align: 'left'},
    { headerName: 'Elo for withdrawals', field: 'elo_for_withdrawals', width: 200, align: 'left'},
    { headerName: 'Is Team', field: 'is_team', width: 200, align: 'left'},
    { headerName: 'Days until first withdrawal', field: 'days_until_first_withdrawal', width: 200, align: 'left'},
    { headerName: 'Days until rest withdrawals', field: 'days_until_rest_withdrawals', width: 200, align: 'left'},
  ];

  return <CustomTable 
  title="remark List"
  addUrl="/remark/create"
  showUrl="/remark/details"
  editUrl="/remark/edit"
  deleteUrl={`${BASE_API_URL}/remark`}
  columns={columns} url={`${BASE_API_URL}/remarks`} />;
}

export default RemarkTable;

import {
    Alert,
    Button,
    Container,
    FormControlLabel,
    Snackbar,
    Switch,
    Typography
  } from '@mui/material';
  // @mui material components
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  
  import MDBox from 'components/MDBox';
  import MDTypography from 'components/MDTypography';
  
  import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
  import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
  import Footer from 'examples/Footer';
  import { BASE_API_URL } from 'constants';
  import { TextField } from '@mui/material';
  import { DataGrid } from '@mui/x-data-grid';
  
  import { useEffect, useImperativeHandle, useRef, useState } from 'react';
  import CustomForm from 'components/CustomForm';
  import { useParams } from 'react-router-dom';
  
  function UserAdminEditForm({}) {
    let { id } = useParams();
  
    const items = [

      {
        key: 'first_name',
        label: 'Name',
        type: 'text',
        required: true
      },
      {
        key: 'last_name',
        label: 'Last Name',
        type: 'text',
        required: true
      },
      {
        key: 'password',
        label: 'Password',
        type: 'text',
        required: true
      },
      {
        key: 'email',
        label: 'Email',
        type: 'email',
        required: true
      },
      {
        key: 'dni',
        label: 'DNI',
        type: 'text',
        required: true
      },
      {
        key: 'address',
        label: 'Address',
        type: 'text',
        required: true
      },
      {
        key: 'rol',
        label: 'Rol',
        type: 'select',
        required: true,
        labelFn: (item) => item?.label,
        valueFn: (item) => {
        return item?.value;
      },
      options: [
        {
          label: 'Admin',
          value: 'admin'
        },
        {
          label: 'Lead operator',
          value: 'lead_operator'
        },
        {
          label: 'Operator',
          value: 'operator'
        }
      ]
    },

 
    
    ];
    const initValue = {
        first_name:"",
        last_name:"",
        password:"",
        email:"",
        dni:"",
        address:"",
        rol:"operator",
     
    };
  
    return (
      <CustomForm
        items={items}
        initValue={initValue}
        url={`${BASE_API_URL}/user_admin/${id}`}
        getPath={`${BASE_API_URL}/user_admin/${id}`}
        successText="User created succesfully"
        errorText="An error has occurred, please try again later"
        successUrl="/users_admin"
        title="UserAdmin Edit"
        id={id}
      />
    );
  }
  
  export default UserAdminEditForm;
  
import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function TradeEditForm({}) {
  let { id } = useParams();

  const items = [
    {
      key: 'trading_account_id',
      label: 'Trading_account',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/trading_accounts_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?._id;
      }
    },

    {
      key: 'direction',
      label: 'Direction',
      type: 'text',
      required: true
    },
    {
      key: 'lots',
      label: 'Lots',
      type: 'text',
      required: true
    },
    {
      key: 'open_time',
      label: 'Open Time',
      type: 'tel',
      required: true
    },
    {
      key: 'open_time_original',
      label: 'Open Time Original',
      type: 'number',
      required: true
    },
    {
      key: 'close_time',
      label: 'Close Time',
      type: 'text',
      required: true
    },
    {
      key: 'close_time_original',
      label: 'Close Time Original',
      type: 'number',
      required: true
    },
    {
      key: 'duration',
      label: 'Duration',
      type: 'number',
      required: true
    },
    {
      key: 'symbol',
      label: 'Symbol',
      type: 'text',
      required: true
    },
    {
      key: 'swap',
      label: 'Swap',
      type: 'text',
      required: true
    },
    {
      key: 'commission',
      label: 'Commission',
      type: 'text',
      required: true
    },
    {
      key: 'profit',
      label: 'Profit',
      type: 'text',
      required: true
    },
    {
      key: 'comment',
      label: 'Comment',
      type: 'text',
      required: true
    },
    {
      key: 'status',
      label: 'Status',
      type: 'text',
      required: true
    },

  ];
  const initValue = {
    mt_user_id: '',
    direction: '',
    lots: '',
    open_time: '',
    open_time_original: '',
    close_time: '',
    close_time_original: '',
    duration: '',
    symbol: '',
    swap: '',
    commission: '',
    profit: '',
    comment: '',
    status: '',
    remarks: '',
    phone: '',
    address: '',
    country: '',
    province: '',
    city: '',
    zipcode: '',
    province: ''
  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/trade/${id}`}
      getPath={`${BASE_API_URL}/trade/${id}`}
      successText="Trade update succesfully"
      errorText="An error has occurred, please try again later"
      successUrl="/trades"
      title="Trade Edit"
      id={id}
    />
  );
}

export default TradeEditForm;

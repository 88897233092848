import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function TradingAccountEditForm({ }) {
  let { id } = useParams();
  console.log(id);

  const items = [
    {
      key: 'user_id',
      label: 'User',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/users_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?._id;
      }
    },
    {
      key: 'avg_return_trade',
      label: 'Avg Return Trade',
      type: 'number',
      required: true
    },
    { key: 'balance', label: 'balance', type: 'number', required: true },
    {
      key: 'can_withdrawal',
      label: 'Can Withdrawal',
      type: 'checkbox',
      required: true
    },
    {
      key: 'daily_drawdown',
      label: 'Daily Drawdown',
      type: 'number',
      required: true
    },
    {
      key: 'days_traded',
      label: 'Days Traded',
      type: 'number',
      required: true
    },
    { key: 'mt_user_id', label: 'Mt User Id', type: 'text', required: true },
    { key: 'next_mt_user_id', label: 'Next Mt User Id.', type: 'text' },
    {
      key: 'broker',
      label: 'Broker',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        {
          label: 'Matchtrader',
          value: 'matchtrader'
        },
        {
          label: 'Metatrader',
          value: 'metatrader'
        }
      ]
    },
    {
      key: 'source',
      label: 'Source',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        {
          label: 'From Back Office',
          value: 'from_back_office'
        },
        {
          label: 'On Sync',
          value: 'sync-task-update_users'
        }
      ]
    },
    {
      key: 'max_balance',
      label: 'Max Balance',
      type: 'number',
      required: true
    },
    { key: 'equity', label: 'Equity', type: 'number', required: true },
    {
      key: 'max_drawdown',
      label: 'Max Drawdown',
      type: 'number',
      required: true
    },
    {
      key: 'max_withdrawal_amount',
      label: 'Max Withdrawal Amount',
      type: 'number',
      required: true
    },
    {
      key: 'profit_target',
      label: 'Profit Target',
      type: 'text',
      required: true
    },
    {
      key: 'remarks',
      label: 'Remarks',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/remarks_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.key;
      }
    },
    {
      key: 'start_balance',
      label: 'Start Balance',
      type: 'number',
      required: true
    },
    {
      key: 'status',
      label: 'status',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Closed',
          value: 'closed'
        },
        {
          label: 'Pending Withdrawal',
          value: 'pending_withdrawal'
        }
      ]
    },
    {
      key: 'total_trades',
      label: 'Total Trades',
      type: 'number',
      required: true
    },
    {
      key: 'trailing_drawdown',
      label: 'Trailing Drawdown',
      type: 'number',
      required: true
    },
    { key: 'win_rate', label: 'Win Rate', type: 'number', required: true },
    {
      key: 'withdrawal_days_traded',
      label: 'Withdrawal Days Traded',
      type: 'number',
      required: true
    },
    { key: 'closed_balance', label: 'Closed Balance', type: 'number', required: true },
    { key: 'closed_equity', label: 'Closed Equity', type: 'number', required: true },


  ];
  const initValue = {
    User_email: '',
    avg_return_trade: '',
    balance: '',
    can_withdrawal: '',
    daily_drawdown: '',
    days_traded: '',
    user_id: '',
    mt_user_id: '',
    broker: '',
    source: '',
    max_balance: '',
    equity: '',
    max_drawdown: '',
    max_withdrawal_amount: '',
    profit_target: '',
    remarks: '',
    start_balance: '',
    status: '',
    total_trades: '',
    trailing_drawdown: '',
    win_rate: '',
    withdrawal_days_traded: '',
    closed_balance: '',
    closed_equity: ''

  };

  return (
    <>

      <CustomForm
        items={items}
        initValue={initValue}
        url={`${BASE_API_URL}/trading_account/${id}`}
        successText="Trading Accounts updated succesfully"
        errorText="An error has occurred, please try again later"
        successUrl="/trading_accounts"
        title="Trading Accounts"
        getPath={`${BASE_API_URL}/trading_account/${id}`}
        id={id}
      />
    </>
  );
}

export default TradingAccountEditForm;

import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();


export const MessageProvider = ({ children }) => {
    const [messageData, _setMessageData] = useState(null);

    const setMessageData = (data) => {
        _setMessageData(data);
        setTimeout(() => {
            _setMessageData(null);
        }, 2000);
    }
    
    return (
        <MessageContext.Provider value={{ messageData, setMessageData }}>
      {children}
    </MessageContext.Provider>
  );
};
export const useMessageContext = () => useContext(MessageContext);
import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';

function orderTable() {

  const columns = [
    {
      headerName: 'Date', field: 'created_at',
      type: 'datetime', width: 200, align: 'left'
    },
    { headerName: 'Name', field: 'firstname', width: 100, align: 'left' },
    { headerName: 'Lastname', field: 'lastname', width: 150, align: 'left' },
    { headerName: 'Email', field: 'email', width: 200, align: 'left' },
    {
      headerName: 'OrderId', field: 'woocomerce_id', width: 100, align: 'left',
      filterOperators: stringFilterOperators
      ,
      renderCell: ({ row: order }) => {
        return (
          <a href={`https://cryptofundtrader.com/wp-admin/post.php?post=${order.woocomerce_id}&action=edit`} target='_blank'>{order.woocomerce_id}</a>
        )
      },
    },
    {
      headerName: 'Country', field: 'country', width: 100, align: 'left',
      filterOperators: stringFilterOperators
    },

    {
      headerName: 'mtUserId', field: 'mt_user_id', width: 200, align: 'left'
      ,
      renderCell: ({ row: order }) => {
        return (
          <Link to={`/user/details/${order.user_id}?mt_user_id=${order.mt_user_id}`}>{order.mt_user_id}</Link>
        )
      },
    },
    {
      headerName: 'mtUserIdL2', field: 'mt_user_id_l2', width: 200, align: 'left',
      renderCell: ({ row: order }) => {
        return order.mt_user_id_l2 == 'N/A' ? order.mt_user_id_l2 : (
          <Link to={`/user/details/${order.user_id}?mt_user_id=${order.mt_user_id_l2}`}>{order.mt_user_id_l2}</Link>
        )
      },
    },
    {
      headerName: 'mtUserIdL3', field: 'mt_user_id_l3', width: 200, align: 'left',
      renderCell: ({ row: order }) => {
        return order.mt_user_id_l3 == 'N/A' ? order.mt_user_id_l3 : (
          <Link to={`/user/details/${order.user_id}?mt_user_id=${order.mt_user_id_l3}`}>{order.mt_user_id_l3}</Link>
        )
      },
    },
    // {
    //   headerName: 'amount ', field: 'amount', width: 200, align: 'left',
    //   filterOperators: numberFilterOperators
    // },

    { headerName: 'remarks', field: 'remarks', width: 200, align: 'left' },
    { headerName: 'evaluation', field: 'evaluation', width: 200, align: 'left' },
    { headerName: 'typeOfEvaluation', field: 'type_evaluation', width: 200, align: 'left' },
    { headerName: 'phase', field: 'phase', width: 150, align: 'left' },
    { headerName: 'status', field: 'status', width: 150, align: 'left' },
    { headerName: 'price', field: 'price', width: 150, align: 'left' },
    { headerName: 'closed date', field: 'closed_date', width: 250, align: 'left' },
    { headerName: 'lastWithdrawal', field: 'last_withdrawal', width: 200, align: 'left' },
    { headerName: 'firstWithdrawal', field: 'first_withdrawal', width: 200, align: 'left' },
    { headerName: 'totalWithdrawals', field: 'total_withdrawals', width: 150, align: 'left' },
    { headerName: 'totalWithdrawalsAmount', field: 'total_withdrawal_amount', width: 250, align: 'left' },










  ];

  return <CustomTable
    title="Order List"
    addUrl="/order/create"
    showUrl="/order/details"
    editUrl="/order/edit"
    deleteUrl={`${BASE_API_URL}/order`}
    columns={columns} url={`${BASE_API_URL}/orders`} />;
}

export default orderTable;

import { TablePagination } from '@mui/material';
import { Box, Button, FormControl, FormHelperText, IconButton, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import { BASE_API_URL } from "constants";
import apiService from "core/customFetch";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { utils, writeFile } from 'xlsx';

const styles = {
    container: {
        marginBottom: '10px',
        height: '40px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: {
            xs: '1 1 auto',
            md: '0 0 20%',
        },
        backgroundColor: 'background.paper',

    },
    select: {
        height: '100%',
    },
    inputMainContainer: {
        flexDirection: {
            xs: 'column',
            md: 'row',
        }, display: 'flex',
        justifyContent: 'space-between'
    },
    inputContainer: {
        padding: '10px',
        flex: {
            xs: '1 1 auto',
            md: '0 0 50%',
        },
        flexDirection: 'column',
        display: 'flex',
    },
    preContainer: {
        width: '100%',
        height: '500px',
        overflowX: 'auto',
    },
    pre: {
        whiteSpace: 'pre',
        overflowX: 'auto',
        display: 'block',
        padding: '10px',
        backgroundColor: 'white',
        fontSize: '10px',
        borderRadius: '4px'
    }
}

const remarks = [
    'k1pn',
    'k2pn',
    'k3pn',
    'k1pa',
    'k2pa',
]

const initalValues = {
    "remark": "k1pa",
    "k1pn": {
        "winTrade": 1.125,
        "lostTrade": -0.2625,
        "withdrawal": 0,
        "winPhase": 90,
        "lostPhase": -50,
    },
    "k2pn": {
        "winTrade": 2.25,
        "lostTrade": -0.5625,
        "withdrawal": 0,
        "winPhase": 90,
        "lostPhase": -50,
    },
    "k3pn": {
        "winTrade": 3.375,
        "lostTrade": -0.75,
        "withdrawal": 243.75,
        "winPhase": 0,
        "lostPhase": -37.5,
    },
    "k1pa": {
        "winTrade": 1.6875,
        "lostTrade": -0.4125,
        "withdrawal": 0,
        "winPhase": 90,
        "lostPhase": -50,
    },
    "k2pa": {
        "winTrade": 2.8125,
        "lostTrade": -0.65625,
        "withdrawal": 243.75,
        "winPhase": 0,
        "lostPhase": -43.75,
    },
}

function Simulator({ }) {
    const [usersGroup, setUsersGroup] = useState('random');
    const [dataByUserGroup, setDataByUserGroup] = useState({});
    const [formValues, setFormValues] = useState(initalValues);
    const [showCode, setShowCode] = useState(false);

    const [calculeInClient, setCalculeInClient] = useState(true);
    const [calculations, setCalculations] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleLocalDownload = () => {
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet(calculations); // Reemplaza 'c' con tus datos
        utils.book_append_sheet(workbook, worksheet, 'Sheet2');
        writeFile(workbook, ('simulator').replace(/ /g,'_').toLowerCase() +'.xlsx');
      };
    // Manejar el cambio de página
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Manejar el cambio en la cantidad de filas por página
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleInputChange = (e, remark) => {
        const value = e.target.value;
        setFormValues({
            ...formValues,
            [remark]: {
                ...formValues[remark],
                [e.target.name]: value, // Update the specific field within the remark object
            },
        });
    };


    useEffect(() => {
        if (usersGroup && !dataByUserGroup[usersGroup]) {
            apiService.post(`${BASE_API_URL}/elo_simulator`, {
                usersGroup,
                calculeInClient
            }).then(res => {
                setDataByUserGroup({
                    ...dataByUserGroup,
                    [usersGroup]: res
                })
            })
        }
    }, [usersGroup, calculeInClient]);


    useEffect(() => {

        // check if all the values are filled
        if (!remarks.every(remark => formValues[remark] && Object.keys(formValues[remark]).length === 5 && Object.values(formValues[remark]).every(value => value !== ''))) {
            return;
        }
        if (calculeInClient) {

            if (dataByUserGroup?.[usersGroup]) {

                const result = Object.keys(dataByUserGroup[usersGroup]).map(_id => {
                    const user = dataByUserGroup[usersGroup][_id];
                    const { name, ...rest } = user;

                    const elo = remarks.reduce((acc, remark) => {
                        if (!rest[remark]) {
                            return acc;
                        }

                        const { lost_ta, lost_trades, remarks, withdrawals, win_ta, win_trades } = rest[remark];
                        const { winTrade, lostTrade, withdrawal, winPhase, lostPhase } = formValues[remark];
                        const win = (win_trades || 0) * winTrade;
                        const lost = Math.abs((lost_trades || 0)) * lostTrade;
                        const withdrawalAmount = (withdrawals?.counter || 0) * withdrawal;
                        const winPhaseAmount = (winPhase || 0) * win_ta;
                        const lostPhaseAmount = (lostPhase || 0) * lost_ta;

                        const total = win + lost + withdrawalAmount + winPhaseAmount + lostPhaseAmount;
                        return {
                            elo: acc.elo + total,
                            remarks: [...acc.remarks, {
                                remark, win,
                                lost,
                                withdrawalAmount,
                                winPhaseAmount,
                                lostPhaseAmount,
                            }],
                        };

                    }, { elo: 0, remarks: [] })
                    const data = remarks.reduce((acc, remark) => {
                        if (!rest[remark]) {
                            return acc;
                        }
                        const { lost_ta,
                            lost_trades,
                            lost_trades_amount,
                            lost_trades_count,
                            remarks,
                            win_ta,
                            win_trades,
                            win_trades_amount,
                            win_trades_count,
                            withdrawals, } = rest[remark];
                        return {
                            lost_ta: `${acc.lost_ta}${remark}: ${lost_ta} \n`,
                            lost_trades: `${acc.lost_trades}${remark}: ${(lost_trades || 0).toFixed(2)} \n`,
                            lost_trades_amount: `${acc.lost_trades_amount}${remark}: ${(lost_trades_amount || 0).toFixed(2)} \n`,
                            lost_trades_count: `${acc.lost_trades_count}${remark}: ${lost_trades_count} \n`,
                            remarks: `${acc.remarks}${remark}: ${remarks} \n`,
                            win_ta: `${acc.win_ta}${remark}: ${win_ta} \n`,
                            win_raw: `${acc.win_raw}${remark}: ${win_trades / 0.1} \n`,
                            win_trades: `${acc.win_trades}${remark}: ${(win_trades || 0).toFixed(2)} \n`,
                            win_trades_amount: `${acc.win_trades_amount}${remark}: ${(win_trades_amount || 0).toFixed(2)} \n`,
                            win_trades_count: `${acc.win_trades_count}${remark}: ${win_trades_count} \n`,
                            withdrawals_count: `${acc.withdrawals_count}${remark}: ${withdrawals?.counter} \n`,
                            withdrawals_total: `${acc.withdrawals_total}${remark}: ${withdrawals?.total_withdrawals} \n`,

                        }
                    }, { 'lost_ta': '', win_raw: '', 'lost_trades': '', 'lost_trades_amount': '', 'lost_trades_count': '', 'remarks': '', 'win_ta': '', 'win_trades': '', 'win_trades_amount': '', 'win_trades_count': '', 'withdrawals_count': '', 'withdrawals_total': '' });
                    return {
                        name,
                        _id,
                        elo: elo?.elo,
                        eloData: elo.remarks.reduce((acc, remark) => {
                            return `${acc}${remark.remark}:trades_win:${remark.win.toFixed(2)} trades_lost${remark.lost.toFixed(2)} \n`
                        }, ''),
                        lost_ta: data.lost_ta,
                        lost_trades: data.lost_trades,
                        lost_trades_amount: data.lost_trades_amount,
                        lost_trades_count: data.lost_trades_count,
                        remarks: data.remarks,
                        win_raw: data.win_raw,
                        win_ta: data.win_ta,
                        win_trades: data.win_trades,
                        win_trades_amount: data.win_trades_amount,
                        win_trades_count: data.win_trades_count,
                        withdrawals_count: data.withdrawals_count,
                        withdrawals_total: data.withdrawals_total,
                    }
                })

                console.log({ result });
                setCalculations(result.sort((a, b) => {
                    if (a.elo > b.elo) {
                        return -1;
                    }
                    if (a.elo < b.elo) {
                        return 1;
                    }
                    return 0;
                }))
            }
            else {
                setCalculations(dataByUserGroup[usersGroup] || [])
            }
        }



    }, [formValues, dataByUserGroup, usersGroup, calculeInClient])

    const paginatedCalculations = calculations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <DashboardLayout >
                {/* <DashboardNavbar /> */}
                <Box
                    sx={styles.container}>
                    <FormControl fullWidth sx={styles.select} >
                        <InputLabel  >Users Group</InputLabel>
                        <Select
                            value={usersGroup || ''}
                            label="Users Group"
                            onChange={(e) => setUsersGroup(e.target.value)}
                            sx={styles.select}
                        >

                            <MenuItem value={'random'}>Random</MenuItem>
                            <MenuItem value={'winners'}>Winners</MenuItem>
                            <MenuItem value={'newers'}>Newers</MenuItem>
                            <MenuItem value={'withdrawals_total'}>Withdrawals</MenuItem>
                            <MenuItem value={'withdrawals_amount'}>Total $ Withdrawals</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={styles.inputMainContainer}>

                    <Box sx={styles.inputContainer}>
                        <FormControl fullWidth sx={styles.select} >
                            <InputLabel id="demo-simple-select-label" >Remarks</InputLabel>
                            <Select
                                label="Users Group"
                                value={formValues.remark || ''}
                                onChange={e => setFormValues({ ...formValues, remark: e.target.value })}
                                sx={styles.select}
                            >
                                {
                                    remarks.map(remark => <MenuItem value={remark}>{remark.toUpperCase()}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl sx={{ margin: '5px 0' }} >
                            <InputLabel >Win Trade</InputLabel>
                            <Input
                                name="winTrade"
                                type="number"
                                value={formValues[formValues.remark]?.winTrade || ''}
                                onChange={e => handleInputChange(e, formValues.remark)} />
                        </FormControl>
                        <FormControl sx={{ margin: '5px 0' }}>
                            <InputLabel >Lost Trade</InputLabel>
                            <Input
                                name="lostTrade"
                                type="number"
                                value={formValues[formValues.remark]?.lostTrade || ''}
                                onChange={e => handleInputChange(e, formValues.remark)} />

                        </FormControl>
                    </Box>
                    <Box sx={styles.inputContainer}>


                        <FormControl sx={{ margin: '5px 0' }}>
                            <InputLabel >Withdrawal</InputLabel>
                            <Input
                                name="withdrawal"
                                type="number"
                                value={formValues[formValues.remark]?.withdrawal || ''}
                                onChange={e => handleInputChange(e, formValues.remark)} />
                        </FormControl>
                        <FormControl sx={{ margin: '5px 0' }}>
                            <InputLabel >Win Phase</InputLabel>
                            <Input value={formValues[formValues.remark]?.winPhase || ''}
                                name="winPhase"
                                type="number"
                                onChange={e => handleInputChange(e, formValues.remark)} />
                        </FormControl>
                        <FormControl sx={{ margin: '5px 0' }}>
                            <InputLabel >Lost Phase</InputLabel>
                            <Input value={formValues[formValues.remark]?.lostPhase || ''}
                                name="lostPhase"
                                type="number"
                                onChange={e => handleInputChange(e, formValues.remark)} />
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={styles.inputMainContainer}>

                <IconButton aria-label="delete" onClick={e => setShowCode(!showCode)}>
                    {showCode ? <VisibilityOffIcon /> : <VisibilityIcon />}  <ListAltIcon />
                </IconButton>

                <IconButton aria-label="excel" onClick={e => handleLocalDownload()}>
                    <DownloadIcon />  
                </IconButton>
                </Box>
                {showCode ? <Box
                    sx={styles.preContainer}>


                    <Box sx={styles.inputMainContainer}>

                        <Box sx={styles.inputContainer}>
                            <code style={styles.pre}>
                                <pre>
                                    {
                                        JSON.stringify(dataByUserGroup[usersGroup] || {}, null, 2)
                                    }
                                </pre>
                            </code>
                        </Box><Box sx={styles.inputContainer}>
                            <code style={styles.pre}>
                                <pre>
                                    {
                                        JSON.stringify(formValues || {}, null, 2)
                                    }
                                </pre>
                            </code>
                        </Box>
                    </Box>


                </Box> : <></>}

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, }} >
                        <TableHead sx={{ display: 'table-header-group !important' }}>
                            <TableRow >
                                <TableCell >Ranking</TableCell>
                                <TableCell >Name</TableCell>
                                <TableCell >Elo</TableCell>
                                <TableCell >Win Phases </TableCell>
                                <TableCell >Lost Phases </TableCell>
                                <TableCell >WIN Num total 0.1%</TableCell>
                                <TableCell >Lost Num total 0.1%</TableCell>
                                {/* <TableCell >Win Trades Amount</TableCell>
                                <TableCell >Lost Trades Amount</TableCell> */}
                                <TableCell >Win Trades Counter</TableCell>
                                <TableCell >Lost Trades Total</TableCell>
                                <TableCell >Withdrawals Amount</TableCell>
                                <TableCell >Withdrawals Counter</TableCell>
                                <TableCell > </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ height: '45vh', }}>
                            {paginatedCalculations.map((row, i) => (
                                <TableRow
                                    key={row._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" >
                                        {i + 1 + page * rowsPerPage}
                                    </TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell >{row.elo?.toFixed(2)}
                                        <br />
                                        {row.eloData}</TableCell>
                                    <TableCell >{row.win_ta} </TableCell>
                                    <TableCell >{row.lost_ta} </TableCell>
                                    <TableCell >{row.win_trades}</TableCell>
                                    <TableCell >{row.lost_trades}</TableCell>
                                    {/* 
                                    <TableCell >{row.win_trades_amount}</TableCell>
                                    <TableCell >{row.lost_trades_amount}</TableCell> */}

                                    <TableCell >{row.win_trades_count}</TableCell>
                                    <TableCell >{row.lost_trades_count}</TableCell>
                                    <TableCell >{row.withdrawals_total}</TableCell>
                                    <TableCell >{row.withdrawals_count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> <TablePagination
                    component="div"
                    count={calculations.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />


            </DashboardLayout >

        </>
    );
}

export default Simulator;


import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

function BoxInfo({ boxInfo }) {

    return (
        <>

            <Grid container spacing={6}>

                <Grid item xs={12} mt={5} md={6} lg={4}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="dark"
                            icon="credit_card"
                            title="Active Trading Accounts"
                            count={boxInfo?.activeTradingAccounts}
                            percentages={
                                [{
                                    amount: boxInfo?.closedTradingAccounts || 0,
                                    label: "closed",
                                }, {
                                    amount: boxInfo?.pendingTradingAccounts || 0,
                                    label: "pending",
                                }, {
                                    amount: boxInfo?.openTradingAccounts || 0,
                                    label: "open",
                                },
                                ]
                            }
                        />
                    </MDBox>
                </Grid>

                <Grid item xs={12} mt={5} md={6} lg={4}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="light"
                            icon="auto_graph"
                            title="Total trades"
                            count={boxInfo?.totalTrades}
                            // percentage={{
                            //     amount: boxInfo?.winTrades || 0,
                            //     label: "win",
                            // }}
                            percentages={
                                [
                                    {
                                        amount: boxInfo?.winTrades || 0,
                                        label: "win",
                                    }
                                    , {
                                    amount: boxInfo?.riskAverage || 0,
                                    label: "Risk Average",
                                }
                                ]
                            }
                        />
                    </MDBox>
                </Grid>

                <Grid item xs={12} mt={5} md={6} lg={4}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="dark"
                            icon="person_add"
                            title="Total Withdrawals"
                            count={boxInfo?.totalWithdrawals}
                            percentage={{
                                amount: boxInfo?.totalWithdrawalsAmount || 0,
                                label: "€",
                            }}
                        />
                    </MDBox>
                </Grid>
            </Grid>


        </>
    );
}

export default BoxInfo;

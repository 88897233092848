import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';

function UserTable() {

  const columns = [


    {
      headerName: 'Name',
      field: 'First_name',
      width: 200,
      align: 'left',

      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 200,
      align: 'left'
    },
    { headerName: 'Email', field: 'User_email', width: 200, align: 'left' },
    { headerName: 'Phone', field: 'phone', width: 200, align: 'left' },
    { headerName: 'Address', field: 'address', width: 200, align: 'left' },
    {
      headerName: 'Created At',
      field: 'created_at',
type: 'datetime',
      width: 200,
      align: 'left',
      filterOperators: dateFilterOperators
    },
    
  ];

  return <CustomTable 
    title="User List"
    addUrl="/user/create"
    showUrl="/user/details"
    editUrl="/user/edit"
    deleteUrl={`${BASE_API_URL}/user`}
    columns={columns} url={`${BASE_API_URL}/users`} 
  />;
}

export default UserTable;

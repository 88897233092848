import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import PlaceholderTypography from 'components/PlaceholderTypography';

function UserDetailSideBar({ user }) {
    const completeName = `${user?.First_name} ${user?.last_name}`;
    const isRentable = !!user?.is_rentable;
    const hasUser = !!user?.First_name;
    const navigate = useNavigate();

    return (
        <>
            {/* Contenedor principal */}
            <Box display="flex" flexDirection="column" p={5}>
                {/* Nombre */}
                <Box>
                    <Typography variant="subtitle1">Name</Typography>
                </Box>
                <Box>
                <PlaceholderTypography variant="h2" placeholderCheck={hasUser}  text={completeName} />
                    
                </Box>

                {/* Es rentable o no */}
                {/* <Box display="flex" flexDirection="row" pt={1}>
                    {isRentable ? (
                        <>
                            <span style={{ color: 'green' }}>↑</span>
                            <span>Is rentable</span>
                        </>
                    ) : (
                        <>
                            <span style={{ color: 'red' }}>↑</span>
                            <span>Not is rentable</span>
                        </>
                    )}
                </Box> */}

                {/* Fecha de creación */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Created at:
                    </Typography>
                <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={new Date(user?.date_registered).toLocaleDateString()} />

                </Box>

                {/* Correo electrónico */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Email:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.User_email} />

                </Box>

                {/* Dirección */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Address:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.address} />
                    
                </Box>

                {/* Ciudad */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        City:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.city} />

                 
                </Box>

                {/* País */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Country:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.country} />

                </Box>

                {/* Teléfono */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Phone:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.phone} />

                </Box>

                {/* Provincia */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Province:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.province} />
                    
                </Box>

                {/* Fuente */}
                <Box display="flex" flexDirection="row" pt={1}>
                    <Typography mr={2} variant="body2">
                        Source:
                    </Typography>
                    <PlaceholderTypography variant="body2" color="textSecondary" placeholderCheck={hasUser}  text={user?.source} />

          
                </Box>
                <Box display="flex" flexDirection="row" mt={2}
                    justifyContent="center"
                >

                    <Button
                    onClick={()=>navigate(`/user/edit/${user._id}`)}
                        variant="contained"
                        startIcon={<EditIcon />}
                    >
                        Go to edit
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default UserDetailSideBar;

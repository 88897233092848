import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';

function BrokerAccountsTable() {
    const columns = [
        {
            headerName: 'Name',
            field: 'First_name',
            width: 200,
            align: 'left',

            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Last Name',
            field: 'last_name',
            width: 200,
            align: 'left'
        },
        {
            headerName: 'Email',
            field: 'User_email',
            width: 200,
            align: 'left'
        },

        {
            headerName: 'User Id',
            field: 'user_id',
            width: 100,
            align: 'left',
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Mt User ID',
            field: 'mt_user_id',
            width: 100,
            align: 'left',
            renderCell: ({ row: ta }) => {
                return (
                    <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
                )
            },
            filterOperators: numberFilterOperators
        },
        {
            headerName: 'Broker',
            field: 'broker',
            width: 100,
            align: 'left',
            filterOperators: stringFilterOperators
        },

        
    ];

    return (
        <CustomTable
            title="BrokerAccounts List"
            addUrl="/broker_account/create"
            editUrl="/broker_account/edit"
            showUrl="/broker_account/details"
            deleteUrl={`${BASE_API_URL}/broker_account`}
            columns={columns}
            url={`${BASE_API_URL}/broker_accounts`}
        />
    );
}

export default BrokerAccountsTable;

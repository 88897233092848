import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function RemarkEditForm({ }) {
  let { id } = useParams();


  const items = [
    {
      key: 'key',
      label: 'Key',
      type: 'text',
      required: true
    },
    {
      key: 'target_profit',
      label: 'Target profit',
      type: 'number',
      required: true
    },
    {
      key: 'amount',
      label: 'Amount',
      type: 'number',
      required: true
    },
    {
      key: 'offer_id_production',
      label: 'offer_id_production',
      type: 'text',
      required: true
    },
    {
      key: 'offer_id_development',
      label: 'offer_id_development',
      type: 'text',
      required: true
    },
    {
      key: 'daily_drawdown',
      label: 'daily_drawdown',
      type: 'number',
      required: true
    },
    {
      key: 'max_drawdown',
      label: 'max_drawdown',
      type: 'number',
      required: true
    },
    {
      key: 'broker',
      label: 'broker',
      type: 'text',
      required: true
    },
    {
      key: 'sku',
      label: 'Sku',
      type: 'number',
      required: true
    },
    {
      key: '_30_seconds_rule',
      label: '30 seconds rule',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]

    },
    {
      key: 'is_tournament',
      label: 'Is Tournament',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]
    },
    {
      key: 'is_accelerated',
      label: 'Is Accelerated',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]
    },
    {
      key: 'is_funded',
      label: 'Is Funded',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]

    },
    {
      key: 'is_istant',
      label: 'Is Istant',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]
    },
    {
      key: 'min_days_traded',
      label: 'Min days traded',
      type: 'number',
      required: true
    },
    {
      key: 'daily_loss_limit',
      label: 'Daily loss limit',
      type: 'number',
      required: true
    },
    {
      key: 'phase',
      label: 'Phase',
      type: 'number',
      required: true
    },
    {
      key: 'phase_target',
      label: 'Phase target',
      type: 'number',
      required: false
    },
    {
      key: 'trailing_loss_limit',
      label: 'Trailing loss limit',
      type: 'number',
      required: false
    },
    {
      key: 'next_remark_id',
      label: 'Next remark id',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/remarks_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?._id;
      },
    },
    {
      key: 'elo_for_lost_trade',
      label: 'Elo for lost trade',
      type: 'number',
      required: true
    },
    {
      key: 'elo_for_phase_lost',
      label: 'Elo for phase lost',
      type: 'number',
      required: true
    },
    {
      key: 'elo_for_phase_win',
      label: 'Elo for phase win',
      type: 'number',
      required: true
    },
    {
      key: 'elo_for_win_trade',
      label: 'Elo for win trade',
      type: 'number',
      required: true
    },
    {
      key: 'elo_for_withdrawals',
      label: 'Elo for withdrawals',
      type: 'number',
      required: true
    },
    {
      key: 'is_team',
      label: 'Is Team',
      type: 'select',
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [

        { label: 'Yes', value: true },
        { label: 'No', value: false || null },
      ]
    },
    {
      key: 'tournament_name',
      label: 'Tournament Name',
      type: 'text',
      required: false
    },
    {
      key: 'days_until_first_withdrawal',
      label: 'Days until first withdrawal',
      type: 'number',
      required: true
    },
    {
      key: 'days_until_rest_withdrawals',
      label: 'Days until rest withdrawals',
      type: 'number',
      required: true
    }

  ];
  const initValue = {
    First_name: '',
    last_name: '',
    User_email: '',
    key: '',
    target_profit: '',
    amount: '',
    offer_id_production: '',
    offer_id_development: '',
    daily_drawdown: '',
    max_drawdown: '',
    broker: '',
    sku: '',
    _30_seconds_rule: '',
    is_tournament: '',
    is_accelerated: '',
    is_funded: '',
    is_istant: '',
    min_days_traded: '',
    daily_loss_limit: '',
    phase: '',
    phase_target: '',
    trailing_loss_limit: '',
    next_remark_id: '',
    elo_for_lost_trade: '',
    elo_for_phase_lost: '',
    elo_for_phase_win: '',
    elo_for_win_trade: '',
    elo_for_withdrawals: '',
    is_team: '',
    tournament_name: '',
    days_until_first_withdrawal: '',
    days_until_rest_withdrawals: '',


  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/remark/${id}`}
      getPath={`${BASE_API_URL}/remark/${id}`}
      successText="remark created succesfully"
      errorText="An error has occurred, please try again later"
      successUrl="/remarks"
      title="remark Create"
      id={id}
    />
  );
}

export default RemarkEditForm;

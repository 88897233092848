import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';

function WithdrawalForm({ }) {
    const items = [
        {
            key: 'user_id',
            label: 'User',
            type: 'select',
            required: true,
            url: `${BASE_API_URL}/users_to_select?page_size=-1`,
            labelFn: (item) => item?.label,
            valueFn: (item) => {
                return item?._id;
            }
        },
        {
            key: 'mt_user_id',
            label: 'Mt User Id',
            type: 'text',
            required: true
        },

        {
            key: 'amount',
            label: 'Amount',
            type: 'number',
            required: true
        },
        {
            key: 'created_at',
            label: 'Create At',
            type: 'text',
            required: true
        },
        {
            key: 'broker',
            label: 'Broker',
            type: 'select',
            required: true,
            labelFn: (item) => item?.label,
            valueFn: (item) => {
                return item?.value;
            },
            options: [
                {
                    label: 'Matchtrader',
                    value: 'matchtrader'
                },
                {
                    label: 'Metatrader',
                    value: 'metatrader'
                }
            ]
        },

        {
            key: 'trader_id',
            label: 'Trader Id',
            type: 'text',
            required: true
        },
      
    ];
    const initValue = {
        First_name: '',
        last_name: '',
        User_email: '',
        user_id: '',
        mt_user_id: '',
        amount: '',
        create_at: '',
        broker: '',
        trader_id: ''
    };

    return (
        <CustomForm
            items={items}
            initValue={initValue}
            url={`${BASE_API_URL}/withdrawal`}
            successText="Withdrawal Accounts updated succesfully"
            errorText="An error has occurred, please try again later"
            successUrl="/withdrawals"
            title="Withdrawals"
        />
    );
}

export default WithdrawalForm;

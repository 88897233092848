import { useNavigate, useParams } from 'react-router-dom';
import { BASE_API_URL } from 'constants';
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, Tab, Tabs, Typography, CardHeader } from '@mui/material';
import MDBox from 'components/MDBox';
import { useEffect, useState } from 'react';
import apiService from 'core/customFetch';

import SimpleChart from 'components/SimpleChart';

function TradingAccountTabs({ tradingAccounts = [], selected }) {
    const navigate = useNavigate();
    const [metadata, setMetadata] = useState({});

    const [filterTA, setFilterTA] = useState(tradingAccounts);
    const [value, setValue] = useState(selected);
    const [filterClosed, setFilterClosed] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(`?tradding_account=${tradingAccounts[newValue]._id}`);
    };


    useEffect(() => {
        if (filterClosed) {
            setFilterTA(tradingAccounts.filter(item => item.status !== 'closed'));
        } else {
            setFilterTA(tradingAccounts);
        }
    }, [filterClosed, tradingAccounts])


    useEffect(() => {
        setValue(selected);
        if (tradingAccounts?.[selected]?._id) {

            apiService.get(`${BASE_API_URL}/trading_account_metadata/${tradingAccounts[selected]._id}`)
                .then(data => {
                    setMetadata(data)

                })
                .catch(err => console.log(err))
        }
    }, [selected, tradingAccounts])


    return (
        <>
            <MDBox>
                <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => {
                    setFilterClosed(!e.target.checked);
                }} />} label="Show closed" />


            </MDBox>
            <MDBox mt={4.5}>
                <Grid container spacing={3}>

                    <MDBox sx={{ width: '100%' }}>
                        <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange}

                                aria-label="basic tabs example">
                                {
                                    filterTA.map((tradingAcount, index) => {
                                        return <Tab label={`MT${tradingAcount?.mt_user_id}`} key={`Tab${tradingAcount._id}`}
                                            sx={{
                                                backgroundColor: tradingAcount?.status === 'closed' ? '#ed9696' : '#68d97c'
                                            }}
                                        />
                                    })
                                }


                            </Tabs>
                        </MDBox>
                        {filterTA.map((tradingAccount, index) => {
                            return <MDBox
                                key={`TabPanel${tradingAccount._id}`}
                                role="tabpanel"
                                hidden={value !== index}
                            >



                                <Grid item xs={12} display="flex" flexDirection={{ xs: 'column', lg: 'row' }}
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    padding={3}
                                >
                                    <Grid item xs={12} lg={8}>
                                        <MDBox flexDirection="column"    >
                                            <Typography variant="h4" color="text" fontWeight="bold" fontSize={14}>
                                                Balances
                                            </Typography>
                                            <SimpleChart data={metadata?.balances} type="line" />

                                        </MDBox>
                                    </Grid>
                                    <Grid container xs={12} lg={4} justifyContent="center"
                                    >

                                        <Card sx={{ minWidth: '80%' }}>
                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    MT UserID
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {tradingAccount?.mt_user_id}
                                                </Typography>
                                            </MDBox>

                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Start date
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {new Date(tradingAccount?.created_at).toLocaleDateString()}
                                                </Typography>
                                            </MDBox>


                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Deposit
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {metadata?.remark?.amount} $ | {metadata?.remark?.key}
                                                </Typography>
                                            </MDBox>

                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Broker
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {tradingAccount?.broker}
                                                </Typography>
                                            </MDBox>

                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Balance
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {tradingAccount?.balance} $
                                                </Typography>
                                            </MDBox>

                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Equity
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {tradingAccount?.equity} $
                                                </Typography>

                                            </MDBox>


                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Last update
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {new Date(tradingAccount?.updated_at).toLocaleDateString()}
                                                </Typography>
                                            </MDBox>
                                            <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                                <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                    Status
                                                </Typography>

                                                <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                    {tradingAccount?.status}
                                                </Typography>
                                            </MDBox>

                                        </Card>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection={{ md: 'column', xl: 'row' }}  >

                                    <Card sx={{ width: '100%', marginLeft: '5px', marginTop: '5px', minHeight: '200px' }} >

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Created At
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {new Date(tradingAccount?.created_at).toLocaleDateString()}
                                            </Typography>
                                        </MDBox>


                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Updated At
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {new Date(tradingAccount?.updated_at).toLocaleDateString()}
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Broker
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.broker}
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Remarks
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.remarks} | {metadata?.remark?.amount} $
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Daily Drawdown
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.daily_drawdown} $
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Updated At Daily Drawdown
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {new Date(tradingAccount?.updated_at_daily_drawdown).toLocaleDateString() + ' ' + new Date(tradingAccount?.updated_at_daily_drawdown).toLocaleTimeString()}
                                            </Typography>
                                        </MDBox>
                                    </Card>

                                    <Card sx={{ width: '100%', marginLeft: '5px', marginTop: '5px', minHeight: '200px' }}  >
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Current Balance
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.balance} $

                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Max Balance
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.max_balance} $
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Min Balance
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.min_balance} $
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                {metadata?.balance_info?.min_balance && metadata?.balance_info?.days_to_recover_initial_balance ? 'Days to recover initial balance' : 'Days since min balance'}
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.balance_info?.min_balance && metadata?.balance_info?.days_to_recover_initial_balance ? metadata?.balance_info?.days_to_recover_initial_balance : metadata?.balance_info?.days_since_min_balance + ' | ' + new Date(metadata?.balance_info?.min_balance_day).toLocaleDateString()}
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Max Drawdown
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {tradingAccount?.max_drawdown} $
                                            </Typography>
                                        </MDBox>

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Max Drawdown Percent
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.max_drawdown_percent} %
                                            </Typography>
                                        </MDBox>





                                    </Card>

                                    <Card sx={{ width: '100%', marginLeft: '5px', marginTop: '5px', minHeight: '200px' }}  >
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Total Win
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.total_win}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Total loss
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.total_loss}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Total trades
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.total_trades}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Trades per day average
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.trades_per_day_average}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Risk average
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.risk_average} $
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Risk percentage
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.risk_percentage} %
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Average lots
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.avg_lots}
                                            </Typography>
                                        </MDBox>







                                    </Card>

                                    <Card sx={{ width: '100%', marginLeft: '5px', marginTop: '5px', minHeight: '200px' }}  >

                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Time Open Trade
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.time_open_trade}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Commission
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.commission_trade} $
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Ratio Win/Loss
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.ratio_win_loss}
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Win Rate
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.total_win_percentage} %
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Win Rate Long
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.win_buy_percentage} %
                                            </Typography>
                                        </MDBox>
                                        <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="row" >
                                            <Typography variant="span" color="text" fontWeight="bold" fontSize={14}>
                                                Win Rate Short
                                            </Typography>

                                            <Typography variant="span" color="text" fontWeight="ligth" fontSize={12}>
                                                {metadata?.trades_data?.win_sell_percentage} %
                                            </Typography>
                                        </MDBox>

                                    </Card>

                                </MDBox>


                                <MDBox display="flex" justifyContent="space-between" pt={1} px={2} flexDirection="column"  >
                                    <Typography variant="h4" color="text" fontWeight="bold" fontSize={14}>
                                        Instruments
                                    </Typography>
                                    <SimpleChart data={metadata?.instrument_data} type="bar" />

                                </MDBox>

                            </MDBox>
                        })}

                    </MDBox>
                </Grid>
            </MDBox>

        </>
    )
}

export default TradingAccountTabs;
import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function BalanceEditForm({ }) {
  let { id } = useParams();
  

  const items = [
    {
      key: 'trading_account_id',
      label: 'Mt User Id ',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/trading_accounts_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?._id;
      }
    },

    {
      key: 'balance',
      label: 'Balance',
      type: 'text',
      required: true
    },
    {
      key: 'date_year_month_day',
      label: 'Date',
      type: 'date',
      required: true
    },



  ];
  const initValue = {
    mt_user_id: '',
    balance: '',
    date_year_month_day: '',
  

  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/balance/${id}`}
      getPath={`${BASE_API_URL}/balance/${id}`}
      successText="User created succesfully"
      errorText="An error has occurred, please try again later"
      successUrl="/balances"
      title="Balance Create"
      id={id}
    />
  );
}

export default BalanceEditForm;

import React, { useEffect, useState } from 'react';
import CustomTable from 'components/CustomTable';
import { BASE_API_URL } from 'constants';
import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';

import { Link } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function BestTradingAccountsTable() {
    const [currentFilter, setCurrentFilter] = useState('winners');



    const columns = [
        {
            headerName: 'Mt User ID',
            field: '_id',
            width: 100,
            align: 'left',
            renderCell: ({ row }) => {
                return (
                    <Link to={`/user/details/${row.user_id}?mt_user_id=${row.mt_user_id}`}>{row.mt_user_id}</Link>
                )
            },
            filterOperators: numberFilterOperators
        },

        {
            headerName: 'Remarks',
            field: 'remark',
            width: 100,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.remark?.key} </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Days Since Min Balance',
            field: 'balance_info',
            width: 150,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.balance_info?.days_since_min_balance} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Days To Recover Initial Balance',
            field: 'balance_info.days_to_recover_initial_balance',
            width: 150,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.balance_info?.days_to_recover_initial_balance} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Min Balance daily',
            field: 'balance_info.min_balance_day',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.balance_info?.min_balance_day} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Min Balance',
            field: 'balance_info.min_balance',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.balance_info?.min_balance} </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Max Drawdown Percent',
            field: 'max_drawdown_percent',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.max_drawdown_percent}% </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Total Trades',
            field: 'trades_data.total_trades',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.total_trades} </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Total Win',
            field: 'trades_data.total_win',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.total_win} </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Total Loss',
            field: 'trades_data.total_loss',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.total_loss} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Risk Avg',
            field: 'trades_data.risk_average',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.risk_average} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Risk %',
            field: 'trades_data.risk_percentage',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.risk_percentage}% </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Trades Per Day Avg',
            field: 'trades_data.trades_per_day_average',
            width: 160,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.trades_per_day_average} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Ratio Win Loss',
            field: 'trades_data.ratio_win_loss',
            width: 120,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.ratio_win_loss} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Total Win %',
            field: 'trades_data.total_win_percentage',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.total_win_percentage}% </span>
                )
            },
            filterOperators: stringFilterOperators
        }, {
            headerName: 'Win Buy %',
            field: 'trades_data.win_buy_percentage',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.win_buy_percentage}% </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Win Sell %',
            field: 'trades_data.win_sell_percentage',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.win_sell_percentage}% </span>
                )
            },
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Avg Lots',
            field: 'trades_data.avg_lots',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.avg_lots} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Time Open Trade',
            field: 'trades_data.time_open_trade',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.time_open_trade} </span>
                )
            },
            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Commission Trade',
            field: 'trades_data.commission_trade',
            width: 110,
            align: 'left', renderCell: ({ row }) => {
                return (
                    <span> {row?.trades_data?.commission_trade} </span>
                )
            },
            filterOperators: stringFilterOperators
        },



    ];

    return (


        <CustomTable
            disableRemoteDownlaod

            title="Best Trading Accounts List"
            additionalFilterComponent={
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentFilter}
                        label="Filter"
                        onChange={(value)=>{
                            setCurrentFilter(value?.target?.value)
                        }}
                        sx={{
                            height: '40px',
                        }}
                    >
                        <MenuItem value={'winners'}>Winners</MenuItem>
                        <MenuItem value={'newers'}>Newers</MenuItem>
                        <MenuItem value={'withdrawals_total'}>Withdrawals</MenuItem>
                        <MenuItem value={'withdrawals_amount'}>Total $ Withdrawals</MenuItem>
                    </Select>
                </FormControl>
            }

            columns={columns}
            url={`${BASE_API_URL}/best_users/${currentFilter}`}
        />

    );
}

export default BestTradingAccountsTable;




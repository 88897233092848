import { useState, useEffect, useMemo } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React example components
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

// Material Dashboard 2 React themes
import theme from 'assets/theme';

// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';

// Material Dashboard 2 React routes
import { routesWithMenuEntry, routesWithoutMenuEntry } from 'routes';

import { useMessageContext } from 'context/message';

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator
} from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import { UserProvider } from 'context/user';
import { Alert, Snackbar } from '@mui/material';
const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route?.collapse) {
      return getRoutes(route.collapse);
    }

    if (route?.route) {
      return (
        <Route
          exact
          path={route.route}
          element={route.component}
          key={route.key}
        />
      );
    }

    return null;
  });

const ROUTES = getRoutes([...routesWithMenuEntry, ...routesWithoutMenuEntry]);

console.log({ ROUTES });
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {messageData, setMessageData} = useMessageContext();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin]
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <UserProvider>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brandWhite}
              brandName="CFT Dashboard"
              routes={routesWithMenuEntry}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/* {configsButton} */}
          </>
        )}
       <div id='main-container'>
       <Routes>
          {ROUTES.map((route) => route)}

          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
       </div>
      </ThemeProvider>
      <Snackbar
        open={messageData?.snackOpen}
        anchorOrigin={{ vertical:'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => messageData?.setSnackOpen(false)}
      >
        <Alert
          onClose={() => messageData?.setSnackOpen(false)}
          severity={messageData?.snackSeverity}
          sx={{ width: '400px' }}
        >
          {messageData?.snackMsg}
        </Alert>
      </Snackbar>
    </UserProvider>
  );
}

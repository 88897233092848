import {
  Alert,
  Button,
  Container,
  FormControlLabel,
  Snackbar,
  Switch,
  Typography
} from '@mui/material';
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { BASE_API_URL } from 'constants';
import { TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import CustomForm from 'components/CustomForm';

function UserForm({}) {
  const items = [
    {
      key: 'First_name',
      label: 'Name',
      type: 'text',
      required: true
    },
    {
      key: 'last_name',
      label: 'Last Name',
      type: 'text',
      required: true
    },
    {
      key: 'User_email',
      label: 'Email',
      type: 'email',
      required: true
    },
    {
      key: 'User_pass',
      label: 'Password',
      type: 'password',
      required: true
    },
    {
      key: 'phone',
      label: 'Phone',
      type: 'tel',
      
    },
    {
      key: 'address',
      label: 'Address',
      type: 'text',
      
    },
    {
      key: 'city',
      label: 'City',
      type: 'text',
      
    },
    {
      key: 'zipcode',
      label: 'ZIP Code',
      type: 'text',
      
    },
    {
      key: 'province',
      label: 'Province',
      type: 'text',
      
    },
    {
      key: 'country',
      label: 'Country',
      type: 'text',
      
    },
    {
      key: 'admin',
      label: 'Is Admin',
      type: 'checkbox',
      required: false
    }
  ];
  const initValue = {
    First_name: '',
    last_name: '',
    User_pass:'',
    User_email: '',
    phone: '',
    address: '',
    city: '',
    zipcode: '',
    province: '',
    country: '',
    phone: '',
    admin: ''
  };

  return (<CustomForm 
    items={items} initValue={initValue} 
    url={`${BASE_API_URL}/user`} 
    successText="User created succesfully"
    errorText="An error has occurred, please try again later" 
    successUrl="/users" title="User Create" />);
}

export default UserForm;


function mainContainer(theme, ownerState) {
    const { palette, breakpoints, transitions } = theme;

    return {

    };
}
function userDashboardTitleContainer(theme, ownerState) {
    const { palette, breakpoints, transitions, functions } = theme;
    const { white, transparent, dark, grey, gradients } = palette;
    const { pxToRem, rgba, linearGradient } = functions;

    return {
        flexDirection: "row",
        borderRadius: 1,
        borderTop: 2,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        borderLeft: 2,
        borderColor: gradients.info.main
    }
}

function userDashboardTitle(theme, ownerState) {
    const { palette, breakpoints, transitions, functions } = theme;
    const { white, transparent, dark, grey, gradients } = palette;
    const { pxToRem, rgba, linearGradient } = functions;

    return {
        color: palette.text.primary,
        border: 0,
        borderBottomLeftRadius: 0,
        padding: '8px 10px',
        color: palette.text.primary,
        // transform: "skewX(-5deg)", /* Cambia el ángulo según tus preferencias */
        clipPath: "polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%)",

        display: "inlineBlock",
        background: linearGradient(gradients.info.main, gradients.info.state),
    }
}

function userDashboardTitleValue(theme, ownerState) {
    const { palette, breakpoints, transitions, functions } = theme;
    const { white, transparent, dark, grey, gradients } = palette;
    const { pxToRem, rgba, linearGradient } = functions;

    return {
        color: palette.text.primary,
        padding: '10px',
    }
}


export {
    mainContainer,
    userDashboardTitleContainer,
    userDashboardTitle,
    userDashboardTitleValue
};

import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';
import { Box, Grid, Skeleton, useMediaQuery, Typography } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import apiService from 'core/customFetch';
import { useLocation } from 'react-router-dom';


import {
  mainContainer,
  userDashboardTitle,
  userDashboardTitleValue,
  userDashboardTitleContainer,
} from './styles/userDetails';
import { useEffect, useState } from 'react';

import BoxInfo from 'components/BoxInfo';
import TradingAccountTabs from 'components/TradingAccountTabs';
import UserDetailSideBar from 'components/UserDetailSideBar';
import PlaceholderTypography from 'components/PlaceholderTypography';


function UserDetailsForm({ }) {
  let { id } = useParams();
  const [user, setUser] = useState({});
  const [tradingAccounts, setTradingAccounts] = useState([]); // [1,2,3
  const [boxInfo, setBoxInfo] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tradingAccount = queryParams.get('tradding_account');
  const mt_user_id = queryParams.get('mt_user_id');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  useEffect(() => {
    apiService.get(`${BASE_API_URL}/userInfo/${id}`)
      .then(data => {
        console.log(data);
        setUser(data?.user);
        setBoxInfo(data?.boxInfo);
        setTradingAccounts(data?.tradingAccounts);
      })
      .catch(err => console.log(err))
  }, [id])
  const _index = tradingAccounts?.findIndex(item => {
    return item.mt_user_id === mt_user_id ||  item._id === tradingAccount
  });
  const index = _index === -1 ? 0 : _index;
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox pb={3}>
          <Grid container spacing={6} sx={theme => mainContainer(theme)}>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={9} style={{ flex: 9, order: isMobile? 2 :1 }}>
                  <Box display="flex"
                    sx={theme => userDashboardTitleContainer(theme)}
                  >
                    <Box sx={theme => userDashboardTitle(theme)}>
                      <Typography variant="h4">CFT</Typography>
                    </Box>
                    <Box sx={theme => userDashboardTitleValue(theme)}>
              
                    <PlaceholderTypography variant="p"  placeholderCheck={user?.First_name}  text={ `${user?.First_name} ${user?.last_name}`} />

                    </Box>
                  </Box>

                  <BoxInfo boxInfo={boxInfo} />

                  <TradingAccountTabs tradingAccounts={tradingAccounts} selected={index} />

                </Grid>

                <Grid item xs={12} md={3} style={{ flex: 3,order: isMobile? 1:2 }}>
                  <UserDetailSideBar user={user} />
                </Grid>



              </Grid>

            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>

    </>
  );
}

export default UserDetailsForm;

import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import UserTable from "components/Users/userTable";
import UserForm from "components/Users/userForm";
import UserEditForm from "components/Users/userEditForm";
import UserDetailsForm from "components/Users/userDetailsForm";
import TradingAccountTable from "components/TradingAccounts/tradingAccountTable";
import TradingAccountEditForm from "components/TradingAccounts/tradingAccountEditForm";
import OrderEditForm from "components/Orders/orderEditForm";
import OrderForm from "components/Orders/orderForm";
import OrderTable from "components/Orders/orderTable";
import TraddingForm from "components/TradingAccounts/tradingAccountForm";
import TradeForm from "components/Trades/tradeForm";
import TradeEditForm from "components/Trades/tradeEditForm";
import TradeTable from "components/Trades/tradeTable";
import BalanceForm from "components/Balances/balanceForm";
import BalanceTable from "components/Balances/balanceTable";
import BalanceEditForm from "components/Balances/balanceEditForm";
import UserAdminEditForm from "components/UsersAdmin/userAdminEditForm";
import UserAdminForm from "components/UsersAdmin/userAdminForm";
import UserAdminTable from "components/UsersAdmin/userAdminTable";
import RemarkEditForm from "components/Remarks/remarkEditForm";
import RemarkForm from "components/Remarks/remarkForm";
import RemarkTable from "components/Remarks/remarkTable";
import TaskLogsTable from "components/TaskLogs/taskLogsTable";
import WithdrawalTable from "components/Withdrawals/withdrawalsTable";
import WithdrawalEditForm from "components/Withdrawals/withdrawalsEditForm";
import WithdrawalForm from "components/Withdrawals/withdrawalsForm";
import PositionTable from "components/Positions/positionsTable";
import PositionDetailsForm from "components/Positions/positionsDetailsForm";
import PositionForm from "components/Positions/positionsForm";
import PositionEditForm from "components/Positions/positionsEditForm";
import BrokerAccountsTable from "components/BrokerAccounts/BrokerAccountsTable";
import BrokerAccountEditForm from "components/BrokerAccounts/BrokerAccountsEditForm";
import BrokerAccountForm from "components/BrokerAccounts/BrokerAccountsForm";
import ClaimTable from "components/Claims/claimTable";
import ClaimEditForm from "components/Claims/claimEditForm";
import ClaimForm from "components/Claims/claimForm";
import BestTradingAccountsTable from "components/TradingAccounts/bestTradingAccountsTable";
import Simulator from "components/Simulator";

import TinyTraderEvaluationForm from "components/TinyTrader/TinyTraderEvaluationForm";
import TinyTraderBalanceForm from "components/TinyTrader/TinyTraderBalanceForm";
import TinyTraderStatus from "components/TinyTrader/TinyTraderStatus";
import AcademyAdd from "components/Academy/AcademyAdd";
import AcademyRemove from "components/Academy/AcademyRemove";
import AcademyList from "components/Academy/AcademyList";

//import TinyTraderLegendEvaluationForm from "components/TinyTrader/TinyTraderLegendEvaluationForm";

export const routesWithMenuEntry = [
  {
    type: "title",
    title: "Simulator",
    key: "simulator",
  },

  // List
  {
    type: "collapse",
    name: "Simulator",
    key: "Simulator",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/simulator",
    component: <Simulator />,
  },
  {
    type: "title",
    title: "Home",
    key: "home_",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  // USERS_ADMIN
  {
    type: "title",
    title: "Users Admin",
    key: "UsersAdmin",
  },

  // crear
  {
    type: "collapse",
    name: "Create",
    key: "userAdminForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user_admin/create",
    component: <UserAdminForm />,
  },
  //listado
  {
    type: "collapse",
    name: "List",
    key: "userAdminTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users_admin",
    component: <UserAdminTable />,
  },
  //USERS

  {
    type: "title",
    title: "Usuarios",
    key: "Users",
  },
  // crear
  {
    type: "collapse",
    name: "Create",
    key: "UserForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user/create",
    component: <UserForm />,
  },
  // listado

  {
    type: "collapse",
    name: "List",
    key: "UserTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: <UserTable />,
  },
  // TRADING_ACCOUNTS
  {
    type: "title",
    title: "TradingAccounts",
    key: "TradingAccounts",
  },
  // crear
  {
    type: "collapse",
    name: "Create",
    key: "TradingAccountsForm",
    icon: <Icon fontSize="small">credit_card</Icon>,
    route: "/trading_account/create",
    component: <TraddingForm />,
  },
  //listado
  {
    type: "collapse",
    name: "List",
    key: "TradingAccountsTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/trading_accounts",
    component: <TradingAccountTable />,
  },
  //best users

  {
    type: "collapse",
    name: "Best Trading Accounts",
    key: "BestTradingAccountsTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/best_users",
    component: <BestTradingAccountsTable />,
  },

  // TRADES
  {
    type: "title",
    title: "Trades",
    key: "Trades",
  },

  //crear
  {
    type: "collapse",
    name: "Create",
    key: "TradesForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/trade/create",
    component: <TradeForm />,
  },
  //listado
  {
    type: "collapse",
    name: "List",
    key: "TradesTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/trades",
    component: <TradeTable />,
  },

  // BALANCES
  {
    type: "title",
    title: "Balances",
    key: "Balances",
  },

  {
    type: "collapse",
    name: "Create",
    key: "BalancesForm",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/balance/create",
    component: <BalanceForm />,
  },

  {
    type: "collapse",
    name: "List",
    key: "BalancesTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/balances",
    component: <BalanceTable />,
  },

  // ORDERS
  {
    type: "title",
    title: "Orders",
    key: "Orders",
  },

  // crear
  {
    type: "collapse",
    name: "Create",
    key: "OrderForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/order/create",
    component: <OrderForm />,
  },
  //listado
  {
    type: "collapse",
    name: "List",
    key: "ordersTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/orders",
    component: <OrderTable />,
  },

  // REMARKS
  {
    type: "title",
    title: "Remarks",
    key: "Remarks",
  },

  // crear
  {
    type: "collapse",
    name: "Create",
    key: "RemarkForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/remark/create",
    component: <RemarkForm />,
  },
  //listado
  {
    type: "collapse",
    name: "List",
    key: "remarksTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/remarks",
    component: <RemarkTable />,
  },

  // TASK_LOGS

  {
    type: "title",
    title: "Task Logs",
    key: "task_logs",
  },

  {
    type: "collapse",
    name: "List",
    key: "taskLogsTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/task_logs",
    component: <TaskLogsTable />,
  },

  // WITHDRAWALS
  {
    type: "title",
    title: "Withdrawals",
    key: "withdrawals",
  },

  // crear
  {
    type: "collapse",
    name: "Create",
    key: "WithdrawalForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/withdrawal/create",
    component: <WithdrawalForm />,
  },
  //listado

  {
    type: "collapse",
    name: "List",
    key: "WithdrawalTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/withdrawals",
    component: <WithdrawalTable />,
  },

  // POSITIONS
  {
    type: "title",
    title: "Positions",
    key: "positions",
  },
  // crear
  {
    type: "collapse",
    name: "Create",
    key: "PositionForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/position/create",
    component: <PositionForm />,
  },

  // List
  {
    type: "collapse",
    name: "List",
    key: "PositionTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/positions",
    component: <PositionTable />,
  },

  // BROKER_ACCOUNTS
  {
    type: "title",
    title: "Broker_accounts",
    key: "broker_accounts",
  },

  // List
  {
    type: "collapse",
    name: "List",
    key: "BrokerAccountTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/broker_accounts",
    component: <BrokerAccountsTable />,
  },
  // Academy
  {
    type: "title",
    title: "Academy",
    key: "academy",
  },
  {
    type: "collapse",
    name: "List",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/academy/list",
    component: <AcademyList />,
  },
  {
    type: "collapse",
    name: "Add item",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/academy/add",
    component: <AcademyAdd />,
  },
  {
    type: "collapse",
    name: "Remove item",
    icon: <Icon fontSize="small">remove</Icon>,
    route: "/academy/remove",
    component: <AcademyRemove />,
  },

  // TINYTRADER
  {
    type: "title",
    title: "Tinytrader",
    key: "tinytrader",
  },
  // crear evaluacion (legend)
  //{
  //  type: "collapse",
  //  name: "Evaluation (Legend)",
  //  key: "CreateLegendEvaluationForm",
  //  icon: <Icon fontSize="small">add</Icon>,
  //  route: "/tiny-trader/create-legend-evaluation",
  //  component: <TinyTraderLegendEvaluationForm />,
  //},
  // agregar balances
  {
    type: "collapse",
    name: "Balance",
    key: "Balance",
    icon: <Icon fontSize="small">attach_money</Icon>,
    route: "/tiny-trader/balance",
    component: <TinyTraderBalanceForm />,
  },
  // crear evaluacion
  {
    type: "collapse",
    name: "Create Evaluation",
    key: "CreateEvaluationForm",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/tiny-trader/create-evaluation",
    component: <TinyTraderEvaluationForm />,
  },
  {
    type: "collapse",
    name: "Change Status",
    key: "TinyTraderStatus",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/tiny-trader/status",
    component: <TinyTraderStatus />,
  },

  // CLAIMS

  {
    type: "title",
    title: "Claims",
    key: "claims",
  },

  // crear
  {
    type: "collapse",
    name: "Create",
    key: "ClaimForm",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/claim/create",
    component: <ClaimForm />,
  },

  // List
  {
    type: "collapse",
    name: "List",
    key: "ClaimTable",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/claims",
    component: <ClaimTable />,
  },
];

export const routesWithoutMenuEntry = [
  {
    route: "/",
    component: <Dashboard />,
  },
  // user
  {
    route: "/user/edit/:id",
    component: <UserEditForm />,
  },
  {
    route: "/user/details/:id",
    component: <UserDetailsForm />,
  },

  // trading account
  {
    route: "/trading_account/edit/:id",
    component: <TradingAccountEditForm />,
  },
  {
    route: "/trading_account/details/:id",
    component: <TraddingForm />,
  },
  {
    route: "/trading_account_metadata_filter/:filter",
    component: <BestTradingAccountsTable />,
  },

  // orders

  {
    route: "/order/edit/:id",
    component: <OrderEditForm />,
  },
  {
    route: "/order/details/:id",
    component: <OrderForm />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },

  // Trades
  {
    route: "/trade/edit/:id",
    component: <TradeEditForm />,
  },
  {
    route: "/trade/details/:id",
    component: <TradeForm />,
  },
  // Balances
  {
    route: "/balance/edit/:id",
    component: <BalanceEditForm />,
  },

  {
    route: "/balance/details/:id",
    component: <BalanceForm />,
  },

  // UserAdmin

  {
    route: "/user_admin/edit/:id",
    component: <UserAdminEditForm />,
  },
  {
    route: "/user_admin/details/:id",
    component: <UserAdminForm />,
  },

  // Remarks

  {
    route: "/remark/edit/:id",
    component: <RemarkEditForm />,
  },
  {
    route: "/remark/details/:id",
    component: <RemarkForm />,
  },

  // Withdrawals

  {
    route: "/withdrawal/edit/:id",
    component: <WithdrawalEditForm />,
  },

  {
    route: "/withdrawal/details/:id",
    component: <WithdrawalForm />,
  },

  // Position

  {
    route: "/position/edit/:id",
    component: <PositionEditForm />,
  },

  {
    route: "/position/details/:id",
    component: <PositionDetailsForm />,
  },

  // Broker
  {
    route: "/broker_account/edit/:id",
    component: <BrokerAccountEditForm />,
  },

  {
    route: "/broker_account/details/:id",
    component: <BrokerAccountForm />,
  },
  // Claim
  {
    route: "/claim/edit/:id",
    component: <ClaimEditForm />,
  },

  {
    route: "/claim/details/:id",
    component: <ClaimForm />,
  },
  {
    route: "/not-found",
    component: <Dashboard />,
  },
];

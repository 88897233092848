import { BASE_API_URL } from "constants";
import CustomTable from "components/CustomTable";

import { stringFilterOperators } from "components/CustomTable";

function AcademyList() {
  const columns = [
    {
      headerName: "Title",
      field: "title",
      width: 300,
      align: "left",
      filterOperators: stringFilterOperators,
    },
    {
      headerName: "Description",
      field: "description",
      width: 300,
      align: "left",
      filterOperators: stringFilterOperators,
    },
    {
      headerName: "URL",
      field: "url",
      width: 300,
      align: "left",
      filterOperators: stringFilterOperators,
    },

    {
      headerName: "Type",
      field: "type",
      width: 150,
      align: "left",
      filterOperators: stringFilterOperators,
    },
    {
      headerName: "Created at",
      field: "created_at",
      width: 275,
      align: "left",
    },
  ];

  return (
    <CustomTable
      title="Academy resources list"
      columns={columns}
      url={`${BASE_API_URL}/academy`}
    />
  );
}

export default AcademyList;

import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';
function PositionTable() {
    const columns = [
        {
            headerName: 'Name',
            field: 'First_name',
            width: 200,
            align: 'left',

            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Last Name',
            field: 'last_name',
            width: 200,
            align: 'left'
        },
        {
            headerName: 'Email',
            field: 'User_email',
            width: 200,
            align: 'left'
        },

        {
            headerName: 'User Id',
            field: 'user_id',
            width: 100,
            align: 'left',
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Mt User ID',
            field: 'mt_user_id',
            width: 100,
            align: 'left',
            renderCell: ({ row: ta }) => {
                return (
                    <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
                )
            },
            filterOperators: numberFilterOperators
        },

        {
            headerName: 'Broker',
            field: 'broker',
            width: 100,
            align: 'left',
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Created at',
            field: 'created_at',
type: 'datetime',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators
        },

        {
            headerName: 'Trader Id',
            field: 'trader_id',
            width: 100,
            align: 'left',
            filterOperators: numberFilterOperators
        },

    ];

    return (
        <CustomTable
            title="Positions List"
            addUrl="/position/create"
            editUrl="/position/edit"
            showUrl="/position/details"
            deleteUrl={`${BASE_API_URL}/position`}
            columns={columns}
            url={`${BASE_API_URL}/positions`}
        />
    );
}

export default PositionTable;

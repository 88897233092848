import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';

function TradingAccountTable() {
  const columns = [
    {
      headerName: 'Mt User ID',
      field: 'mt_user_id',
      width: 100,
      align: 'left',
      renderCell: ({ row: ta }) => {
        return (
          <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
        )
      },
      filterOperators: numberFilterOperators
    },

    {
      headerName: 'status',
      field: 'status',
      width: 100,
      align: 'left',
      filterOperators: stringFilterOperators
    },

    {
      headerName: 'Name',
      field: 'First_name',
      width: 100,
      align: 'left',

      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 100,
      align: 'left'
    },
    { headerName: 'Email', field: 'User_email', width: 250, align: 'left' },

    {
      headerName: 'Remarks',
      field: 'remarks',
      width: 200,
      align: 'left',
      filterOperators: stringFilterOperators
    },

    {
      headerName: 'Balance',
      field: 'balance',
      width: 100,
      align: 'left',
      filterOperators: numberFilterOperators
    },

    {
      headerName: 'Equity',
      field: 'equity',
      width: 100,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Days Traded',
      field: 'days_traded',
      width: 50,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Start Balance',
      field: 'start_balance',
      width: 150,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Daily Drawdown',
      field: 'daily_drawdown',
      width: 200,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Max Drawdown',
      field: 'max_drawdown',
      width: 100,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Profit Target',
      field: 'profit_target',
      width: 100,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Avg Return Trade',
      field: 'avg_return_trade',
      width: 150,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Closed Date',
      field: 'closed_date',
      width: 250,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Closed Equity',
      field: 'closed_equity',
      width: 150,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Closed Balance',
      field: 'closed_balance',
      width: 150,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Status Reason',
      field: 'status_reason',
      width: 200,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Created at',
      field: 'created_at',
type: 'datetime',
      width: 250,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Update at',
      field: 'updated_at',
      width: 250,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Update at Daily Drawdown',
      field: 'updated_at_daily_drawdown',
      width: 250,
      align: 'left',
      filterOperators: numberFilterOperators
    }
  ];

  return (
    <CustomTable
      title="Trading Accounts List"
      addUrl="/trading_account/create"
      editUrl="/trading_account/edit"
      /*bestTradingAccounts = "trading_account_metadata_filter"*/
      customShowUrl={(ta) => `/user/details/${ta.user_id}?tradding_account=${ta._id}`}

      deleteUrl={`${BASE_API_URL}/trading_account`}
      columns={columns}
      url={`${BASE_API_URL}/trading_accounts`}
    />
  );
}

export default TradingAccountTable;

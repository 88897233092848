import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
  
  function TradeForm({}) {
    const items = [
      {
        key: 'mt_user_id',
        label: 'Trading_account',
        type: 'select',
        required: true,
        url: `${BASE_API_URL}/trading_accounts_to_select?page_size=-1`,
        labelFn: (item) => item?.label,
        valueFn: (item) => {
          return item?.mt_user_id;
        }
      },
  
      {
        key: 'direction',
        label: 'Direction',
        type: 'text',
        required: true
      },
      {
        key: 'lots',
        label: 'Lots',
        type: 'text',
        required: true
      },
      {
        key: 'open_time',
        label: 'Open Time',
        type: 'tel',
        required: true
      },
      {
        key: 'open_time_original',
        label: 'Open Time Original',
        type: 'number',
        required: true
      },
      {
        key: 'close_time',
        label: 'Close Time',
        type: 'text',
        required: true
      },
      {
        key: 'close_time_original',
        label: 'Close Time Original',
        type: 'number',
        required: true
      },
      {
        key: 'duration',
        label: 'Duration',
        type: 'number',
        required: true
      },
      {
        key: 'symbol',
        label: 'Symbol',
        type: 'text',
        required: true
      },
      {
        key: 'swap',
        label: 'Swap',
        type: 'text',
        required: true
      },
      {
        key: 'commission',
        label: 'Commission',
        type: 'text',
        required: true
      },
      {
        key: 'profit',
        label: 'Profit',
        type: 'text',
        required: true
      },
      {
        key: 'comment',
        label: 'Comment',
        type: 'text',
        required: true
      },
      {
        key: 'status',
        label: 'Status',
        type: 'select',
        required: true,
        labelFn: (item) => item?.label,
        valueFn: (item) => {
          return item?.value;
        },
        options: [
          {
            label: 'Active',
            value: 'active'
          },
          {
            label: 'Closed',
            value: 'closed'
          }
        ]
      },
    ];
    const initValue = {
      mt_user_id: '',
      direction: '',
      lots: '',
      remarks: '',
      phone: '',
      address: '',
      country: '',
      province: '',
      city: '',
      zipcode: '',
      province: ''
    };
  
  
    return (
      <CustomForm
        items={items}
        initValue={initValue}
        url={`${BASE_API_URL}/trade`}
        successText="User created succesfully"
        errorText="An error has occurred, please try again later"
        successUrl="/trades"
        title="Trade"
      />
    );
  }
  
  export default TradeForm;
  
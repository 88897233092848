import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({open, handleClose, title, description, actions }) {

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {
            description
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            actions?.map(action=>{
                return <Button key={action.label} onClick={()=>handleClose(action.value)}>{action.label}</Button>
            })
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

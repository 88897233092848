import React from 'react';
import { Line , Bar } from 'react-chartjs-2';


const SimpleChart = ({data, type}) => {

  if(!data?.datasets?.length) return (<div>No hay datos</div>)
  
  return (
    <div>
      {
        type === 'line' ?
        <Line
          data={data}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        /> :
        <Bar
          data={data}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      }
    </div>
  );
};

export default SimpleChart;

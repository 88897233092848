import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';


function ClaimTable() {
  const columns = [
    {
      headerName: 'Created By',
      field: 'created_by',
      width: 200,
      align: 'left'
    },
    {
      headerName: 'Created By Id',
      field: 'created_by_id',
      width: 250,
      align: 'left',
      filterOperators: stringFilterOperators
    },

    {
      headerName: 'Name',
      field: 'First_name',
      width: 200,
      align: 'left',

      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 200,
      align: 'left'
    },
    { headerName: 'Email', field: 'User_email', width: 200, align: 'left' },


    {
      headerName: 'Mt User ID',
      field: 'mt_user_id',
      width: 100,
      align: 'left',
      renderCell: ({ row: ta }) => {
          return (
              <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
          )
      },
      filterOperators: numberFilterOperators
  },

    {
      headerName: 'Created At',
      field: 'created_at',
type: 'datetime',
      width: 200,
      align: 'left'
    },
    {
      headerName: 'Is Delete',
      field: 'is_delete',
      width: 200,
      align: 'left'
    },
    
  ];

  return <CustomTable
    title="Claim List"
    addUrl="/claim/create"
    editUrl="/claim/edit"
    deleteUrl={`${BASE_API_URL}/claim`}
    columns={columns} url={`${BASE_API_URL}/claims`} />;
}

export default ClaimTable;

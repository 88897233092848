import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';
function WithdrawalTable() {
    const percentageFormatter = (params) => {
        return `${params.value} %`;
    };

    const columns = [
        {
            headerName: 'Name',
            field: 'First_name',
            width: 200,
            align: 'left',

            filterOperators: stringFilterOperators
        },
        {
            headerName: 'Last Name',
            field: 'last_name',
            width: 200,
            align: 'left'
        },
        {
            headerName: 'Email',
            field: 'User_email',
            width: 200,
            align: 'left'
        },


        {
            headerName: 'Mt User ID',
            field: 'mt_user_id',
            width: 100,
            align: 'left',
            renderCell: ({ row: ta }) => {
                return (
                    <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
                )
            },
            filterOperators: numberFilterOperators
        },


        {
            headerName: 'Amount',
            field: 'amount',
            width: 100,
            align: 'left',
            filterOperators: stringFilterOperators
        },

        {
            headerName: 'Created at Withdrawal',
            field: 'created_at',
            type: 'datetime',
            width: 300,
            align: 'left',
            filterOperators: numberFilterOperators
        },
        {
            headerName: 'Created at Trading account',
            field: 'created_at_trading_account',
            width: 300,
            align: 'left',
            filterOperators: numberFilterOperators
        },

        {
            headerName: 'Remarks',
            field: 'remarks',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators
        },
        {
            headerName: 'Days Traded',
            field: 'days_traded',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators
        },

        {
            headerName: 'Profit Split',
            field: 'profit_split',
            width: 200,
            align: 'left',
            filterOperators: numberFilterOperators,
            valueFormatter: percentageFormatter
        }

    ];

    return <CustomTable
        title="Withdrawals List"
        addUrl="/withdrawal/create"
        editUrl="/withdrawal/edit"
        deleteUrl={`${BASE_API_URL}/withdrawal`}
        columns={columns}
        url={`${BASE_API_URL}/withdrawals`}
    />;

}

export default WithdrawalTable;

import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function PositionDetailsForm({ }) {
    let { id } = useParams();
    console.log(id);

    const items = [
        {
            key: 'user_id',
            label: 'User',
            type: 'text',
            required: true
        },
        {
            key: 'mt_user_id',
            label: 'Mt User Id',
            type: 'text',
            required: true
        },

        {
            key: 'broker',
            label: 'Broker',
            type: 'text',
            required: true,

        },

        {
            key: 'created_at',
            label: 'Create At',
            type: 'text',
            required: true
        },

        {
            key: 'trader_id',
            label: 'Trader Id',
            type: 'text',
            required: true
        },
        {
            key: 'action',
            label: 'Action',
            type: 'text',
            required: true
        },

        {
            key: 'activation',
            label: 'Activation',
            type: 'text',
            required: true
        },
        {
            key: 'reason',
            label: 'Reason',
            type: 'text',
            required: true
        },
        {
            key: 'position',
            label: 'Position',
            type: 'number',
            required: true,
        },

        {
            key: 'login',
            label: 'Login',
            type: 'number',
            required: true
        },
        {
            key: 'symbol',
            label: 'Symbol',
            type: 'text',
            required: true
        },
        {
            key: 'volume',
            label: 'Volume',
            type: 'number',
            required: true
        },
        {
            key: 'profit',
            label: 'Profit',
            type: 'number',
            required: true
        },
        {
            key: 'swap',
            label: 'Swap',
            type: 'number',
            required: true
        },
        {
            key: 'comment',
            label: 'Comment',
            type: 'text',
            required: true
        },
        {
            key: 'contractSize',
            label: 'contractSize',
            type: 'number',
            required: true
        },
        {
            key: 'priceCurrent',
            label: 'priceCurrent Id',
            type: 'number',
            required: true
        },
        {
            key: 'priceOpen',
            label: 'priceOpen',
            type: 'number',
            required: true
        },
        {
            key: 'dateCreate',
            label: 'dateCreate',
            type: 'text',
            required: true
        },
        {
            key: 'stopLoss',
            label: 'stopLoss',
            type: 'number',
            required: true
        },
        {
            key: 'takeProfit',
            label: 'takeProfit',
            type: 'number',
            required: true
        },
    
        {
            key: 'clientId',
            label: 'clientId',
            type: 'text',
            required: true
        },
        {
            key: 'id',
            label: 'Id',
            type: 'text',
            required: true
        },
        {
            key: 'instrument',
            label: 'instrument',
            type: 'text',
            required: true
        },
        {
            key: 'dateCreateCleaned',
            label: 'dateCreateCleaned',
            type: 'text',
            required: true
        },
        {
            key: 'openTime',
            label: 'openTime',
            type: 'date',
            required: true
        }

    ];
    const initValue = {
        user_id: '',
        mt_user_id: '',
        create_at: '',
        broker: '',
        trader_id: '',
        action: '',
        activation: '',
        reason: '',
        position: '',
        login: '',
        symbol: '',
        volume: '',
        profit: '',
        swap: '',
        comment: '',
        contractSize: '',
        priceCurrent: '',
        priceOpen: '',
        dateCreate: '',
        stopLoss: '',
        takeProfit: '',
        broker: '',
        clientId: '',
        id: '',
        instrument: '',
        dateCreateCleaned: '',
        openTime: '',
    };

    return (
        <>

            <CustomForm
                items={items}
                initValue={initValue}
                url={`${BASE_API_URL}/position/${id}`}
                successText="Positions updated succesfully"
                errorText="An error has occurred, please try again later"
                successUrl="/positions"
                title="Positions"
                getPath={`${BASE_API_URL}/position/${id}`}
                id={id}
                readOnly={true}
            />
        </>
    );
}

export default PositionDetailsForm;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function menuBtn(theme, ownerState) {
    const { palette, breakpoints,transitions } = theme;
    const { miniSidenav } = ownerState;

    return {
        display: "none",
        visibility: "hidden",

        transition: transitions.create(["left"], {
            easing: miniSidenav ? transitions.easing.easeInOut: transitions.easing.easeOutIn,
            duration: transitions.duration.shorter,
        }),
        [breakpoints.down("xl")]: {
            display: "fixed",
            visibility: "visible",
            top: "25px",
            left: miniSidenav ? "30px" : "290px",
            zIndex: "1000",
        },

    };
}


export { menuBtn, };

import { createContext, forwardRef, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import apiService from "core/customFetch";

const LISTBOX_PADDING = 8; // px

const renderRow = (labelFn, valueFn) => (props) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }
  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {labelFn ? labelFn(dataSet[1]) : dataSet[1][valueFn]}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = (labelFn, valueFn) =>
  forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    console.log("#### children", children);
    console.log("####  other", other);
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow(labelFn, valueFn)}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});
export default function CustomSelect({
  url,
  label,
  id,
  name,
  value: externalValue,
  defaultValue,
  onChange, // event {taget:{name, value}}
  labelFn,
  valueFn,
  options,
}) {
  const [OPTIONS, setOPTIONS] = useState([]);
  const [selectedValue, setSelectedValue] = useState(externalValue || defaultValue);

  useEffect(() => {
    if (options) return setOPTIONS(options);
    if (!url) return;
    apiService.get(url).then((data) => {
      setOPTIONS(data.content);
    });
  }, [url, options]);

  // Manejador para el evento onChange del Autocomplete
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange({ target: { name, value: valueFn(newValue) } });
    }
  };

  return (
    <>
      <Autocomplete
        id="virtualize-demo"
        sx={{ width: "100%" }}
        disableListWrap
        value={OPTIONS?.find((item) => valueFn(item) === externalValue) || null}
        onChange={handleAutocompleteChange}
        getOptionLabel={(option) => (labelFn ? labelFn(option) : option[id])}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent(labelFn, valueFn)}
        options={OPTIONS || []}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option, state) => [props, option, state.index]}
        renderGroup={(params) => params}
      />
    </>
  );
}

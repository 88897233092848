import { BASE_API_URL } from "constants";
import CustomForm from "components/CustomForm";

function TinyTraderBalanceForm({}) {
  const items = [
    { key: "mt_user_id", label: "Account ID", type: "text", required: true },
    { key: "balance", label: "Balance", type: "number", required: true },
    {
      key: "type",
      label: "Type",
      type: "select",
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.value;
      },
      options: [
        {
          label: "Add balance",
          value: "add",
        },
        {
          label: "Remove balance",
          value: "remove",
        },
      ],
    },
  ];
  const initValue = {
    mt_user_id: "",
    balance: "",
    type: "add",
  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/tinytrader/balance`}
      successText="Balance added successfully"
      errorText="An error has occurred, please try again later"
      successUrl="/tiny-trader/balance"
      title="TinyTrader Balance"
    />
  );
}

export default TinyTraderBalanceForm;

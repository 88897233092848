import { useState } from 'react';

// react-router-dom components
import {  Navigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';


// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { useUserContext } from 'context/user';
import { Alert } from '@mui/material';
import { BASE_API_URL } from 'constants';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { user, setUser, setToken } = useUserContext();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState('');


  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = async () => {
    try {
      const response = await fetch(BASE_API_URL+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
      .then(res => res.json())

      if (response.token && response.user) {
        setUser(response.user);
        setToken(response.token);
      } else {
        setSnackOpen(true);
        setTimeout(() => {
          setSnackOpen(false);
        },2000)
        setSnackMsg(response.msg);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
     
      {snackOpen &&  <Alert
          onClose={() => setSnackOpen(false)}
          severity={'error'}
          sx={{ width: '100%' }}
        >
          {snackMsg}
        </Alert>}
     
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleSignIn('user@example.com', 'password123')}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      
      </Card>
    </BasicLayout>
  );
}

export default Basic;

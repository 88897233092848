import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function BrokerAccountEditForm({ }) {
    let { id } = useParams();
    console.log(id);

    const items = [
        {
            key: 'mt_user_id',
            label: 'Trading_account',
            type: 'select',
            required: true,
            url: `${BASE_API_URL}/trading_accounts_to_select?page_size=-1`,
            labelFn: (item) => item?.label,
            valueFn: (item) => {
              return item?.mt_user_id;
            }
          },

        {
            key: 'mt_user_id',
            label: 'Mt User Id',
            type: 'text',
            required: true
        },

        {
            key: 'broker',
            label: 'Broker',
            type: 'text',
            required: true,

        },

        {
            key: 'clientId',
            label: 'Client Id',
            type: 'text',
            required: true
        },

        {
            key: 'login',
            label: 'Login',
            type: 'text',
            required: true
        },
        {
            key: 'name',
            label: 'Name',
            type: 'text',
            required: true
        },
        {
            key: 'country',
            label: 'Country',
            type: 'text',
            required: true
        },
        {
            key: 'city',
            label: 'City',
            type: 'text',
            required: true
        },
        {
            key: 'address',
            label: 'Address',
            type: 'text',
            required: true
        },
        {
            key: 'phone',
            label: 'Phone',
            type: 'text',
            required: true
        },
        {
            key: 'email',
            label: 'Email',
            type: 'text',
            required: true
        },
        {
            key: 'province',
            label: 'Province',
            type: 'text',
            required: true
        },
        {
            key: 'remarks',
            label: 'Remarks',
            type: 'text',
            required: true
        },


    ];
    const initValue = {
        mt_user_id: '',
        broker: '',
        clientId: '',
        login: '',
        name: '',
        country: '',
        city: '',
        address: '',
        phone: '',
        email: '',
        province: '',
        remarks: '',
    };

    return (
        <>

            <CustomForm
                items={items}
                initValue={initValue}
                url={`${BASE_API_URL}/broker_account/${id}`}
                successText="Broker Accounts updated succesfully"
                errorText="An error has occurred, please try again later"
                successUrl="/broker_accounts"
                title="Broker Accounts"
                getPath={`${BASE_API_URL}/broker_account/${id}`}
                id={id}
            />
        </>
    );
}

export default BrokerAccountEditForm;

import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';
function BalanceTable() {
  const columns = [
    {
      headerName: 'Name',
      field: 'First_name',
      width: 200,
      align: 'left',

      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 200,
      align: 'left'
    },
    { headerName: 'Email', field: 'User_email', width: 200, align: 'left' },

    
    {
      headerName: 'MT User ID',
      field: 'mt_user_id',
      width: 100,
      align: 'left',

      renderCell: ({row:ta}) => {
        return (
          <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
        )
      },
      filterOperators:  stringFilterOperators
    },
    {
      headerName: 'Balance',
      field: 'balance',
      width: 100,
      align: 'left',
      filterOperators: numberFilterOperators
    },
    {
      headerName: 'Created At',
      field: 'created_at',
type: 'datetime',
      width: 200,
      align: 'left'
    },
    {
      headerName: 'Broker',
      field: 'broker',
      width: 200,
      align: 'left'
    },
  ];

  return <CustomTable 
  title="Balance List"
  addUrl="/balance/create"
  editUrl="/balance/edit"
  deleteUrl={`${BASE_API_URL}/balance`}
  columns={columns} url={`${BASE_API_URL}/balances`} />;
}

export default BalanceTable;

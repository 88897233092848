import {
  Alert,
  Button,
  Container,
  FormControlLabel,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BASE_API_URL } from "constants";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "components/CustomSelect";
import apiService from "core/customFetch";
import { useMessageContext } from "context/message";

function CustomForm({
  items,
  initValue,
  title,
  url,
  successText,
  errorText,
  successUrl,
  getPath,
  id,
  readOnly,
  onTypeChange,
}) {
  const navigate = useNavigate();
  const { messageData, setMessageData } = useMessageContext();

  const [formData, setFormData] = useState(initValue);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackMsg, setSnackMsg] = useState("User Created Successfully");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Call onTypeChange if type field is changed
    if (name === "type" && onTypeChange) {
      onTypeChange(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = items.reduce((acc, item) => {
      return {
        ...acc,
        [item.key]: formData[item.key] ?? "",
      };
    }, {});

    console.log(url);
    console.log(body);

    try {
      const response = await apiService[getPath ? "put" : "post"](
        url,
        getPath
          ? {
              ...body,
              _id: id,
            }
          : body
      );

      setSnackOpen(true);
      if (!response.error) {
        setMessageData({
          snackOpen: true,
          snackSeverity: "success",
          snackMsg: successText,
          setSnackOpen: setSnackOpen,
        });
        navigate(successUrl);
      } else {
        setMessageData({
          snackOpen: true,
          snackSeverity: "error",
          snackMsg: response.error,
          setSnackOpen: setSnackOpen,
        });
      }
    } catch (error) {
      console.log(error);

      setMessageData({
        snackOpen: true,
        snackSeverity: "error",
        snackMsg: error,
        setSnackOpen: setSnackOpen,
      });
    }
  };

  useEffect(() => {
    if (getPath) {
      const fetchData = async () => {
        try {
          const data = await apiService.get(getPath);
          setFormData(data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [getPath]);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3} className="first-child h100db">
        <Grid container spacing={6} className="grid-container h100db">
          <Grid item xs={12}>
            <Card className="h100b">
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
              </MDBox>
              <MDBox
                pt={3}
                sx={{
                  display: "flex",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <form
                  onSubmit={handleSubmit}
                  className="customForm"
                  style={{ width: "100%" }}
                >
                  <Grid container spacing={2} p={5} pt={0} pb={15}>
                    {items.map((item, i) => (
                      <Grid item xs={12} key={item.key + "-" + i}>
                        {item.type === "checkbox" ? (
                          <FormControlLabel
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: item.key,
                                  value: e.target.checked,
                                },
                              });
                            }}
                            control={<Switch />}
                            key={item.key}
                            value={formData[item.key]}
                            checked={formData[item.key]}
                            label={item.label}
                            labelPlacement="end"
                            name={item.key}
                            id={item.key}
                          />
                        ) : (
                          <></>
                        )}
                        {[
                          "text",
                          "email",
                          "number",
                          "tel",
                          "password",
                        ].includes(item.type) ? (
                          <TextField
                            variant="outlined"
                            required={item.required}
                            fullWidth
                            type={item.type}
                            id={item.key}
                            key={item.key}
                            label={item.label}
                            name={item.key}
                            value={formData[item.key]}
                            onChange={handleChange}
                            readOnly={readOnly}
                          />
                        ) : (
                          <></>
                        )}
                        {item.type === "select" ? (
                          <>
                            {/* {formData[item.key]} */}
                            <CustomSelect
                              key={item.key}
                              label={item.label}
                              id={item.key}
                              name={item.key}
                              options={item.options}
                              url={item.url}
                              value={formData[item.key]}
                              onChange={handleChange}
                              readOnly={readOnly}
                              labelFn={item.labelFn}
                              valueFn={item.valueFn}
                            ></CustomSelect>
                          </>
                        ) : (
                          <></>
                        )}

                        {item.type === "date" ? (
                          <>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              key={item.key}
                            >
                              <DemoContainer components={["DateTimePicker"]}>
                                <DateTimePicker
                                  color="primary"
                                  name={item.key}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: item.key,
                                        value: e.$d,
                                      },
                                    });
                                  }}
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  readOnly={readOnly}
                                  label={item.label}
                                  value={
                                    typeof formData[item.key] === "string"
                                      ? dayjs(formData[item.key])
                                      : formData[item.key]
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    ))}
                    {!readOnly ? (
                      <Grid item xs={12} mb={15}>
                        <Button type="submit" fullWidth variant="contained">
                          Save
                        </Button>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomForm;

import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

const localStorageUser = JSON.parse(localStorage.getItem('user') || 'null' );

export const UserProvider = ({ children }) => {
    const [user, setInternalUser] = useState(localStorageUser);
    const [token, setInternalToken] = useState(localStorage.getItem('token') || null);

    const setUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user));
        setInternalUser(user);
    }

    const setToken = (token) => {
        localStorage.setItem('token', token);
        setInternalToken(token);
    }

    
    return (
        <UserContext.Provider value={{ user, setUser, token, setToken }}>
      {children}
    </UserContext.Provider>
  );
};
export const useUserContext = () => useContext(UserContext);
import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';
import { Link } from 'react-router-dom';

function TradeTable() {

  const columns = [

    {
      headerName: 'Trader id',
      field: 'trader_id',
      width: 200,
      align: 'left',
      filterOperators: stringFilterOperators
    },

    {
      headerName: 'Mt user id',
      field: 'mt_user_id',
      width: 200,
      align: 'left',
      renderCell: ({row:ta}) => {
        return (
          <Link to={`/user/details/${ta.user_id}?tradding_account=${ta._id}`}>{ta.mt_user_id}</Link>
        )
      },
      filterOperators: stringFilterOperators
      // ,
      // renderCell: ({row:trade}) => {
      //   return (
      //     <Link to={`/user/details/${trade.user_id}?mt_user_id=${trade.mt_user_id}`}>{trade.mt_user_id}</Link>
      //   )
      // },
    },


    {
      headerName: 'Name',
      field: 'First_name',
      width: 200,
      align: 'left',
      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 200,
      align: 'left'
    },

    { headerName: 'Email', field: 'User_email', width: 200, align: 'left' },
    {
      headerName: 'Broker',
      field: 'broker',
      width: 200,
      align: 'left',
      filterOperators: stringFilterOperators
    },

    { headerName: 'Direction', field: 'direction', width: 200, align: 'left' },
    { headerName: 'Lots', field: 'lots', width: 200, align: 'left' },
    { headerName: 'Open time', field: 'open_time', width: 200, align: 'left' },
    { headerName: 'Open time original', field: 'Open_time_original', width: 200, align: 'left' },
    { headerName: 'Close time', field: 'close_time', width: 200, align: 'left' },
    { headerName: 'Close time original', field: 'close_time_original', width: 200, align: 'left' },
    { headerName: 'Duration', field: 'duration', width: 200, align: 'left' },
    { headerName: 'Symbol', field: 'symbol', width: 200, align: 'left' },
    { headerName: 'Swap', field: 'swap', width: 200, align: 'left' },
    { headerName: 'Commission', field: 'commission', width: 200, align: 'left' },
    { headerName: 'Profit', field: 'profit', width: 200, align: 'left' },
    { headerName: 'Comment', field: 'comment', width: 200, align: 'left' },
    { headerName: 'Status', field: 'status', width: 200, align: 'left' },
    { headerName: 'Created at', field: 'created_at',
type: 'datetime', width: 200, align: 'left' },







  ];

  return (<CustomTable
    title="Trade List"
    addUrl="/trade/create"
    editUrl="/trade/edit"
    deleteUrl={`${BASE_API_URL}/trade`}
    columns={columns}
    url={`${BASE_API_URL}/trades`}
  />
  );
}

export default TradeTable;

import {
    Alert,
    Button,
    Container,
    FormControlLabel,
    Snackbar,
    Switch,
    Typography
  } from '@mui/material';
  // @mui material components
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  
  import MDBox from 'components/MDBox';
  import MDTypography from 'components/MDTypography';
  
  import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
  import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
  import Footer from 'examples/Footer';
  import { BASE_API_URL } from 'constants';
  import { TextField } from '@mui/material';
  import { DataGrid } from '@mui/x-data-grid';
  
  import { useEffect, useImperativeHandle, useRef, useState } from 'react';
  import CustomForm from 'components/CustomForm';
  import { useParams } from 'react-router-dom';
  
  function OrderEditForm({}) {
    let { id } = useParams();
  
    const items = [
      {
        key: 'User_email',
        label: 'User',
        type: 'select',
        required: true,
        url: `${BASE_API_URL}/users_to_select?page_size=-1`,
        labelFn: (item) => item?.label,
        valueFn: (item) => {
          return item?.User_email;
        }
      },
 
        {
            key: 'sku',
            label: 'Sku',
            type: 'number',
            required: true
          },
      {
        key: 'firstname',
        label: 'Name',
        type: 'text',
        required: true
      },
      {
        key: 'lastname',
        label: 'Last Name',
        type: 'text',
        required: true
      },
      {
        key: 'email',
        label: 'Email',
        type: 'email',
        required: true
      },
      {
        key: 'remarks',
        label: 'Remarks',
        type: 'text',
        required: true
      },
      {
        key: 'phone',
        label: 'Phone',
        type: 'tel',
        required: true
      },
      {
        key: 'address',
        label: 'Address',
        type: 'text',
        required: true
      },
      {
        key: 'country',
        label: 'Country',
        type: 'text',
        required: true
      },
      {
        key: 'province',
        label: 'Province',
        type: 'text',
        required: true
      },
      {
        key: 'city',
        label: 'City',
        type: 'text',
        required: true
      }
 
 
    
    ];
    const initValue = {
      id:'',
      sku: '',
      firstname: '',
      lastname: '',
      email: '',
      remarks: '',
      phone: '',
      address: '',
      country: '',
      province: '',
      city: '',
      province: ''
     
    };
  
    return (
      <CustomForm
        items={items}
        initValue={initValue}
        url={`${BASE_API_URL}/order/${id}`}
        getPath={`${BASE_API_URL}/order/${id}`}
        successText="User created succesfully"
        errorText="An error has occurred, please try again later"
        successUrl="/order"
        title="Order Create"
        id={id}
      />
    );
  }
  
  export default OrderEditForm;
  
import { BASE_API_URL } from 'constants';
import { FilterInput } from 'components/CustomTable';
import CustomTable from 'components/CustomTable';

import { stringFilterOperators } from 'components/CustomTable';
import { numberFilterOperators } from 'components/CustomTable';
import { dateFilterOperators } from 'components/CustomTable';

function UserAdminTable() {

  const columns = [

    {
      headerName: 'Name',
      field: 'first_name',
      width: 200,
      align: 'left',

      filterOperators: stringFilterOperators
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      width: 200,
      align: 'left'
    },
    { headerName: 'Email', field: 'email', width: 280, align: 'left' },
    { headerName: 'DNI', field: 'dni', width: 200, align: 'left' },
    { headerName: 'Address', field: 'address', width: 200, align: 'left' },
    { headerName: 'Rol', field: 'rol', width: 200, align: 'left' },
    
  ];

  return <CustomTable 
  title="User Admin List"
  addUrl="/user_admin/create"
  editUrl="/user_admin/edit"
  deleteUrl={`${BASE_API_URL}/user_admin`}
  columns={columns} url={`${BASE_API_URL}/users_admin`} />;
}

export default UserAdminTable;
import { BASE_API_URL } from "constants";
import CustomForm from "components/CustomForm";

function TinyTraderEvaluationForm({}) {
  const items = [
    {
      key: "email",
      label: "Email",
      type: "text",
      required: true
    },
    {
      key: "remarks",
      label: "Remarks",
      type: "select",
      required: true,
      url: `${BASE_API_URL}/remarks_to_select?page_size=-1&query_broker=tinytrader`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.key;
      },
    },
  ];
  const initValue = {
    user_id: "",
    remarks: "",
  };

  return (
    <CustomForm
      items={items}
      initValue={initValue}
      url={`${BASE_API_URL}/tinytrader/evaluation`}
      successText="Evaluation created successfully"
      errorText="An error has occurred, please try again later"
      successUrl="/tiny-trader/create-evaluation"
      title="TinyTrader Evaluation"
    />
  );
}

export default TinyTraderEvaluationForm;

import { BASE_API_URL } from 'constants';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';

function ClaimForm({ }) {


  const items = [


    {
      key: 'mt_user_id',
      label: 'Mt user id',
      type: 'select',
      required: true,
      url: `${BASE_API_URL}/trading_accounts_to_select?page_size=-1`,
      labelFn: (item) => item?.label,
      valueFn: (item) => {
        return item?.mt_user_id;
      }
    },

        {
          key: 'comments',
          label: 'Comments',
          type: 'text',
          required: true
        },
      ];
      const initValue = {
        mt_user_id: '',
        user_admin: '',
        comments: '',
        created_at: '',

      };

      return(
    <CustomForm
      items = { items }
      initValue = { initValue }
      url = {`${BASE_API_URL}/claim`}
      successText = "Claim created succesfully"
      errorText = "An error has occurred, please try again later"
      successUrl = "/claims"
      title = "Claim Create"
    />
  );
}

export default ClaimForm;

import React, { useState } from "react";
import { BASE_API_URL } from "constants";
import CustomForm from "components/CustomForm";

function AcademyAdd() {
  // Base items array (common for all types)
  const [items, setItems] = useState([
    { key: "title", label: "Title", type: "text", required: true },
    { key: "description", label: "Description", type: "text", required: true },
    { key: "url", label: "URL", type: "text", required: true },
    { key: "image", label: "Image URL", type: "text", required: true },
    {
      key: "type",
      label: "Type",
      type: "select",
      required: true,
      labelFn: (item) => item?.label,
      valueFn: (item) => item?.value,
      options: [
        { label: "Virtual class", value: "virtual_class" },
        { label: "Tutorial videos", value: "tutorial_videos" },
        { label: "Ebook", value: "ebooks" },
        { label: "Video class", value: "video_classes" },
        { label: "Live stream", value: "live_streams" },
        { label: "Podcast", value: "podcasts" },
      ],
    },
    {
      key: "date",
      label: "Start date (UTC)",
      type: "date",
      required: true,
    },
  ]);

  const defaultItems = {
    title: "",
    description: "",
    url: "",
    type: "virtual_class",
    image: "",
    category: "active",
  };

  const handleTypeChange = (type) => {
    if (type === "virtual_class") {
      setItems((prevItems) => [
        ...prevItems.filter(
          (item) => item.key !== "date" && item.key !== "category"
        ),
        {
          key: "date",
          label: "Start date (UTC)",
          type: "date",
          required: true,
        },
      ]);
    } else if (type == "live_streams") {
      setItems((prevItems) => [
        ...prevItems.filter(
          (item) => item.key !== "date" && item.key !== "category"
        ),
        {
          key: "date",
          label: "Start date (UTC)",
          type: "date",
          required: true,
        },
        {
          key: "category",
          label: "Status",
          type: "select",
          required: true,
          labelFn: (item) => item?.label,
          valueFn: (item) => item?.value,
          options: [
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ],
        },
      ]);
    } else {
      setItems(
        items.filter((item) => item.key !== "date" && item.key !== "category")
      );
    }
  };

  return (
    <CustomForm
      items={items}
      initValue={defaultItems}
      url={`${BASE_API_URL}/academy/add`}
      successText="Item added successfully"
      errorText="An error has occurred, please try again later"
      successUrl="/academy/add"
      title="Academy Add"
      onTypeChange={handleTypeChange}
    />
  );
}

export default AcademyAdd;

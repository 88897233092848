/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import { BASE_API_URL } from "constants";
import apiService from "core/customFetch";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    apiService.get(`${BASE_API_URL}/dashboard_statisctics`)
    .then(data => {
      setDashboardData(data)
    })
  },[])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="person_add"
                title="New Accounts"
                count={dashboardData?.users?.total_users}
                percentage={{
                  color: dashboardData?.users?.percentage_increase_users > 0 ? "success" : 'error',
                  amount: dashboardData?.users?.percentage_increase_users > 0 ? `+${dashboardData?.users?.percentage_increase_users}%` : `${dashboardData?.users?.percentage_increase_users}%`,
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="subtitles"
                title="Active Trading Accounts"
                count={dashboardData?.trading_accounts_active?.total_active_trading_accounts}
                percentage={{
                  color: dashboardData?.trading_accounts_active?.percentage_increase_active_trading_accounts > 0 ? "success" : 'error',
                  amount: dashboardData?.trading_accounts_active?.percentage_increase_active_trading_accounts > 0 ? `+${dashboardData?.trading_accounts_active?.percentage_increase_active_trading_accounts}%` : `${dashboardData?.trading_accounts_active?.percentage_increase_active_trading_accounts}%`,
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="subtitles_off"
                color="error"
                title="Closed Trading Accounts"
                count={dashboardData?.trading_accounts_closed?.total_closed_trading_accounts}
                percentage={{
                  color: dashboardData?.trading_accounts_closed?.percentage_increase_closed_trading_accounts > 0 ? "success" : 'error',
                  amount: dashboardData?.trading_accounts_closed?.percentage_increase_closed_trading_accounts > 0 ? `+${dashboardData?.trading_accounts_closed?.percentage_increase_closed_trading_accounts}%` : `${dashboardData?.trading_accounts_closed?.percentage_increase_closed_trading_accounts}%`,
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="currency_exchange"
                title="Withdrawals"
                count={dashboardData?.withdrawals?.total_withdrawals}
                percentage={{
                  color: dashboardData?.withdrawals?.percentage_increase_withdrawals > 0 ? "success" : 'error',
                  amount: dashboardData?.withdrawals?.percentage_increase_withdrawals > 0 ? `+${dashboardData?.withdrawals?.percentage_increase_withdrawals}%` : `${dashboardData?.withdrawals?.percentage_increase_withdrawals}%`,
                  label: "than last week",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>


        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Orders"
                  description="Last week orders performance"
                  date="last week"
                  chart={dashboardData?.orders_chart_data || []}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
